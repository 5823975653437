const getPaymentsValue = (payments) => {
  let newValue = Number(payments);

  if (newValue === 13 || newValue === 15 || newValue === 17 || newValue === 20 || newValue === 23) {
    newValue = newValue + 1;
  }

  if (newValue === 19 || newValue === 22) {
    newValue = newValue + 2;
  }

  return newValue;
};

const decreasePayments = (prevState) => {
  let newValue = prevState - 1;

  if (newValue === 13 || newValue === 15 || newValue === 17 || newValue === 19 || newValue === 22) {
    newValue = newValue - 1;
  }

  if (newValue === 20 || newValue === 23) {
    newValue = newValue - 2;
  }

  return newValue;
};

const increasePayments = (prevState) => {
  let newValue = prevState + 1;

  if (newValue === 13 || newValue === 15 || newValue === 17 || newValue === 20 || newValue === 23) {
    newValue = newValue + 1;
  }

  if (newValue === 19 || newValue === 22) {
    newValue = newValue + 2;
  }

  return newValue;
};

export const paymentsHelper = {
  getPaymentsValue,
  decreasePayments,
  increasePayments,
};
