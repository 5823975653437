const Withdrawal = Object.freeze({
  WireTransfer: 'T',
  BranchOffice: 'R',
  Wallet: 'B',
  Redpagos: 'P',
});

export const handleInstructionsText = (validated, withdraw) => {
  if (validated) {
    switch (withdraw) {
      case Withdrawal.WireTransfer:
        return 'Lo recibirás en tu cuenta bancaria.';
      case Withdrawal.BranchOffice:
        return 'Podés ir a la sucursal a retirarlo.';
      case Withdrawal.Wallet:
        return 'Ya tenés el saldo acreditado en tu billetera.';
      case Withdrawal.Redpagos:
        return 'Podés ir a Redpagos a retirarlo.';
      default:
        break;
    }
  } else {
    return 'Quedate atento a nuestro llamado.';
  }
};
