/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import SimuladorCheckDesktop from '../../assets/ico/desktop/icon-simulador-check-desktop.svg';
import SobreVosDesktop from '../../assets/ico/desktop/icon-sobrevos-active-desktop.svg';
import Final from '../../assets/ico/mobile/final2.svg';
import Retiro from '../../assets/ico/mobile/retiro2.svg';
import SimuladorCheckMobile from '../../assets/ico/mobile/simulador-check.svg';
import SobreVosMobile from '../../assets/ico/mobile/sobre-vos-active.svg';
import SpinnerLoader from '../../assets/lotties/spinner-loader-white.json';
import '../../assets/styles.css';
import TyC from '../../assets/tyc/Terminos-y-Condiciones.pdf';
import Footer from '../../components/UI/Footer/Footer';
import DocumentNumberField from '../../components/UI/Inputs/DocumentNumber/DocumentNumberField';
import PhoneNumberField from '../../components/UI/Inputs/PhoneNumber/PhoneNumberField';
import LottieConfig from '../../components/UI/LottieConfig/LottieConfig';
import Navbar from '../../components/UI/Navbar/Navbar';
import { getUser } from '../../services/about.service';

const About = () => {
  const cedulaRegex = /([0-9]){8}/;
  const celularRegex = /(09)[0-9]{7}/;
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({});
  const [tyc, setTyc] = useState(true);
  const [cedulaErrorVisible, setCedulaErrorVisible] = useState(false);
  const [celularErrorVisible, setCelularErrorVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validation_digit = (ci) => {
    var a = 0;
    var i = 0;
    if (ci.length <= 6) {
      for (i = ci.length; i < 7; i++) {
        ci = '0' + ci;
      }
    }
    for (i = 0; i < 7; i++) {
      a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10;
    }
    if (a % 10 === 0) {
      return 0;
    } else {
      return 10 - (a % 10);
    }
  };

  const clean_ci = (ci) => {
    return ci.replace(/\D/g, '');
  };

  const validate_ci = (ci) => {
    ci = clean_ci(ci);
    var dig = ci[ci.length - 1];
    ci = ci.replace(/[0-9]$/, '');
    return dig == validation_digit(ci);
  };

  useEffect(() => {
    if (!location.state) history.push('/');
    setData(location.state);
  }, [location]);

  const handleChangeCelular = useCallback((e) => {
    setData(
      {
        ...data,
        celular: e.target.value,
      },
      [setData],
    );
  });

  const handleChangeCedula = useCallback((e) => {
    setData(
      {
        ...data,
        cedula: e.target.value,
      },
      [setData],
    );
  });

  const handleTyC = (e) => {
    const { checked } = e.target;
    setTyc(checked);
  };

  const fetchIsUser = async (body) => {
    setIsLoading(true);
    const response = await getUser(body);
    setData({
      ...data,
      uuid: response.data.data.lead.uuid,
    });

    if (response.data.data.requestInProcess) {
      history.push({
        pathname: '/losentimos',
        state: { data, response: response.data },
      });
    } else if (response.data.data.isUser) {
      if (response.data.data.offerAmount === 0) {
        history.push({
          pathname: '/losentimos',
          state: { data, response: response.data },
        });
      } else {
        history.push({
          pathname: '/credit',
          state: { ...data, response: response.data.data },
        });
      }
    } else {
      history.push({
        pathname: '/additionalInformation',
        state: data,
      });
    }
    setIsLoading(false);
    return response;
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (cedulaRegex.test(data.cedula) === false) {
      e.preventDefault();
      setCedulaErrorVisible(true);
    } else {
      setCedulaErrorVisible(false);
    }

    if (celularRegex.test(data.celular) === false) {
      e.preventDefault();
      setCelularErrorVisible(true);
    } else {
      setCelularErrorVisible(false);
    }

    if (validate_ci(data.cedula) === false) {
      e.preventDefault();
      setCedulaErrorVisible(true);
    } else {
      setCedulaErrorVisible(false);
    }

    if (
      tyc &&
      celularRegex.test(data.celular) &&
      cedulaRegex.test(data.cedula) &&
      validate_ci(data.cedula) &&
      !data.noOffer
    ) {
      e.preventDefault();
      const body = {
        leadUuid: data.uuid || data?.data.uuid,
        identificationDocument: data.cedula,
        phoneNumber: data.celular,
      };
      fetchIsUser(body);
    } else {
      e.preventDefault();
    }
  };

  return (
    !!location.state && (
      <>
        <Navbar />
        <div className="cuerpo cuerpo--slide2">
          <div className="lat-top" />
          <div className="i-linea">
            <div className="circulo" />
            <div className="linea linea--roja linea-1" />
          </div>
          <div className="titulo">
            <h2 className="title">Completá tus datos y pedilo</h2>
          </div>
          <div className="i-sim">
            <div className="icono">
              <img src={SimuladorCheckMobile} alt="Coin Icon" className="mobile" />
              <img src={SimuladorCheckDesktop} alt="Coin Icon" className="desktop" />
            </div>
            <div className="linea linea--roja linea-2 linea-2--slide2" />
          </div>
          <div className="lateral-sim">
            <p className="txt-lateral txt--rojo">Simulador</p>
          </div>
          <div className="card card--check simulador">
            <p className="card-title">Simulador</p>
            <span className="--rotar check" />
          </div>
          <div className="i-sob">
            <div className="icono">
              <img src={SobreVosMobile} alt="Sobre vos" className="mobile" />
              <img src={SobreVosDesktop} alt="Sobre vos" className="desktop" />
            </div>
            <div className="linea linea--gris linea-3 linea-3--slide2" />
          </div>
          <div className="lateral-sob">
            <p className="txt-lateral txt--blanco txt-lateral--active">Sobre vos</p>
          </div>
          <div className="card card--sombra card--active card--slide2 sobre-vos">
            <div className="card__header">
              <div>
                <p className="card-title">Sobre vos</p>
                <span className="--rotar arrow" />
              </div>
              <hr className="linea-separador" />
            </div>
            <form onSubmit={sendForm} onKeyPress={(e) => e.key === 'Enter' && sendForm(e)}>
              <div className="datos datos--slide2">
                <p className="txt-medium">Cédula de identidad *</p>
                <DocumentNumberField type="tel" value={data.cedula} onChange={handleChangeCedula} required />
                <p className="txt-medium">Celular *</p>
                <PhoneNumberField type="tel" value={data.celular} onChange={handleChangeCelular} required />
              </div>
              <div className="align-checkbox-items">
                <div className="checkbox" id="checkbox">
                  <input
                    id="tyc"
                    type="checkbox"
                    name="tyc"
                    onClick={handleTyC}
                    required
                    defaultChecked={tyc}
                  />
                  <label className="tyc-label" htmlFor="tyc">
                    <p>
                      Acepto
                      <a className="tyc-a" href={TyC} download="Terminos y condiciones.pdf">
                        términos y condiciones
                      </a>
                    </p>
                  </label>
                </div>
              </div>
              <p className={cedulaErrorVisible ? 'errorVisible' : 'errorHidden'}>
                Ingrese su cédula completa, los puntos y guion se agregaran automaticamente (ej: 12345678)
              </p>
              <p className={celularErrorVisible ? 'errorVisible' : 'errorHidden'}>
                Escribe tu celular correctamente.
              </p>
              <div className="desktop relleno relleno--slide2" />
              <div className="card__txt">
                <p>*Campos obligatorios</p>
              </div>
              <div className="card__separador">
                <hr className="linea-separador" />
              </div>
              <div className="card__botones">
                <button
                  onClick={() => history.push({ pathname: '/', state: data })}
                  className="boton boton--outline-primary boton--vovler"
                >
                  Volver
                </button>
                <button type="submit" className="boton boton--primary" disabled={isLoading}>
                  <div className="align-lottie-btn">
                    Continuar
                    {isLoading && (
                      <LottieConfig animationData={SpinnerLoader} customHeight={20} customWidth={20} loop/>
                    )}
                  </div>
                </button>
              </div>
            </form>
          </div>
          <div className="i-forma">
            <div className="icono icono--desactivado">
              <img src={Retiro} alt="Retiro" />
            </div>
            <div className="linea linea--gris linea-3" />
          </div>
          <div className="lateral-forma">
            <p className="txt-lateral txt--gris">Forma de retiro</p>
          </div>
          <div className="card card--sombra card--disabled forma-retiro">
            <p>Forma de retiro</p>
          </div>
          <div className="i-est">
            <div className="icono icono--desactivado">
              <img src={Final} alt="Final" />
            </div>
          </div>
          <div className="lateral-est">
            <p className="txt-lateral txt--gris">Estado final</p>
          </div>
          <div className="card card--sombra card--disabled estado-final">
            <p>Estado final</p>
          </div>
          <div className="lat-bot" />
          <div className="i-foot" />
          <div className="foot" />
        </div>
        <Footer />
      </>
    )
  );
};

export default About;
