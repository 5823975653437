import axios from '../axios';

export const withdrawal = async (data) => {
  const response = await axios.post('/about/withdrawal', data);
  return response;
};

export const newUser = async (data) => {
  const response = await axios.post(`/about/new-user`, data);
  return response;
};

export const getUser = async (data) => {
  const response = await axios.post('/about', data);
  return response;
};
