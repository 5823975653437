/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { isEmpty } from '../../../../utils/isEmpty';
import classes from './SelectDropdown.module.css';

const SelectDropdown = ({
  onChange,
  name,
  id,
  options,
  required,
  labelText,
  returnValueFormat,
  displayText,
  optionValue,
}) => {
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [optionChosen, setOptionChosen] = useState({});
  const [search, setSearch] = useState('');
  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  const contructorReturnValue = (e) => (returnValueFormat === 'e' ? e : optionChosen);

  const handleShowDropdown = (search) => setShowDropdown(search !== '');

  const filterSearch = (e) => {
    const query = e.target.value;
    handleShowDropdown(query);
    setSearch(query);
    setSearchedOptions(options.filter((option) => option.name.toLowerCase()?.includes(query.toLowerCase())));
  };

  useEffect(() => {
    setSearchedOptions(options);
  }, [options]);

  useEffect(() => {
    setShowDropdown(false);
    setSearch(optionChosen[displayText]);
    !isEmpty(optionChosen) && onChange(contructorReturnValue());
  }, [optionChosen]);

  return (
    <div className={classes.MainDiv}>
      <p className={classes.Label}>
        {labelText} {required && '*'}
      </p>
      <div className={classes.SelectDiv} ref={dropdownRef}>
        <input
          type="text"
          placeholder={labelText}
          id={id}
          onChange={filterSearch}
          onFocus={() => setShowDropdown(true)}
          value={search}
        />
        <ul
          className={showDropdown && !!searchedOptions.length ? classes.SelectOptions : classes.HideDropdown}
        >
          {searchedOptions.map((option, index) => (
            <li
              key={index}
              value={option[optionValue]}
              id={option.id}
              className={classes.Option}
              name={name}
              onClick={() => setOptionChosen(option)}
            >
              {option[displayText]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectDropdown;
