import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import FinalDesktop from '../../assets/ico/desktop/icon-final-active-desktop.svg';
import RetiroDesktop from '../../assets/ico/desktop/icon-retiro-check-desktop.svg';
import SimuladorCheckDesktop from '../../assets/ico/desktop/icon-simulador-check-desktop.svg';
import SobreVosDesktop from '../../assets/ico/desktop/icon-sobrevos-check-desktop.svg';
import Final from '../../assets/ico/mobile/final2.svg';
import Retiro from '../../assets/ico/mobile/retiro-check.svg';
import SimuladorCheckMobile from '../../assets/ico/mobile/simulador-check.svg';
import SobreVosMobile from '../../assets/ico/mobile/sobre-vos-check.svg';
import '../../assets/styles.css';
import Footer from '../../components/UI/Footer/Footer';
import Navbar from '../../components/UI/Navbar/Navbar';
import { addFullstopOnThousands } from '../../utils/number.helper';

const Status = () => {
  const history = useHistory();
  const location = useLocation();
  const data = location.state;
  const [cedulaFormateada, setCedulaFormateada] = useState('');
  const [withdrawName, setWithdrawName] = useState(data?.withdraw);
  const [finishText, setFinishText] = useState('');

  var cedulaArray = data?.data?.cedula.split('') || data?.cedula;
  const formattedDate = moment(data?.response.expirationAt).format('DD-MM-YYYY');
  const creditData = data?.response.lead.betterOfferAccepted
    ? {
        amount: data?.response.betterOfferAmount,
        installments: data?.response.betterOfferInstallements,
        installmentsAmount: data?.response.betterOfferInstallmentAmount,
      }
    : {
        amount: data?.response.offerAmount,
        installments: data?.response.offerInstallments,
        installmentsAmount: data?.response.offerInstallment,
      };

  useEffect(() => {
    if (!data) history.push('/');
    if (cedulaArray !== undefined) {
      setCedulaFormateada(
        `${cedulaArray[0]}.${cedulaArray[1]}${cedulaArray[2]}${cedulaArray[3]}.${cedulaArray[4]}${cedulaArray[5]}${cedulaArray[6]}-${cedulaArray[7]}`,
      );
    }
  }, [cedulaArray, history, data]);

  switch (withdrawName) {
    case 'cuenta':
      setWithdrawName('Cuenta bancaria');
      setFinishText(
        'Confirmá los datos, te contactaremos para validarlo y en las próximas horas lo depositaremos en tu cuenta.',
      );
      break;
    case 'cdlc':
      setWithdrawName('Sucursal Crédito de la Casa');
      setFinishText(
        'Confirmá los datos, te contactaremos para validarlo y podrás pasar a buscarlo por cualquier punto de atención de Crédito de la Casa sólo con tu cédula.',
      );
      break;
    case 'billetera':
      setWithdrawName('Billetera Móvil');
      setFinishText(
        'Confirmá los datos, te contactaremos para validarlo y en las próximas horas lo depositaremos en tu Billetera Móvil.',
      );
      break;
    case 'Redpagos':
      setWithdrawName('Sucursal Redpagos');
      setFinishText(
        'Confirmá los datos, te contactaremos para validarlo y podrás pasar a buscarlo por Redpagos sólo con tu cédula.',
      );
      break;
    default:
      break;
  }

  const withdrawNameSelected =
    (withdrawName === 'T' && 'Transferencia bancaria') ||
    (withdrawName === 'R' && 'Sucursal') ||
    (withdrawName === 'B' && 'Billetera') ||
    (withdrawName === 'P' && 'Redpagos');

  const nextPage = () => {
    history.push({
      pathname: '/final',
      state: data,
    });
  };

  return (
    !!data && (
      <>
        <Navbar />
        <div className="cuerpo cuerpo--slide14">
          <div className="lat-top" />
          <div className="i-linea">
            <div className="circulo" />
            <div className="linea linea--roja linea-1" />
          </div>
          <div className="titulo">
            <h2 className="title">¡Tu crédito está pre-aprobado!</h2>
          </div>
          <div className="i-sim">
            <div className="icono">
              <img src={SimuladorCheckMobile} alt="Simulador" className="mobile" />
              <img src={SimuladorCheckDesktop} alt="Simulador" className="desktop" />
            </div>
            <div className="linea linea--roja linea-2 linea-2--slide2" />
          </div>
          <div className="lateral-sim">
            <p className="txt-lateral txt--rojo">Simulador</p>
          </div>
          <div className="card card--check simulador">
            <p className="card-title">Simulador</p>
            <span className="--rotar check" />
          </div>
          <div className="i-sob">
            <div className="icono">
              <img src={SobreVosMobile} alt="Sobre vos" className="mobile" />
              <img src={SobreVosDesktop} alt="Sobre vos" className="desktop" />
            </div>
            <div className="linea linea--roja linea-3 linea-3--slide3" />
          </div>
          <div className="lateral-sob">
            <p className="txt-lateral txt--rojo">Sobre vos</p>
          </div>
          <div className="card card--check sobre-vos">
            <p className="card-title">Sobre vos</p>
            <span className="--rotar check" />
          </div>
          <div className="i-forma">
            <div className="icono">
              <img src={Retiro} alt="Retiro" className="mobile" />
              <img src={RetiroDesktop} alt="Retiro" className="desktop" />
            </div>
            <div className="linea linea--roja linea-4 linea-4--slide14" />
          </div>
          <div className="lateral-forma">
            <p className="txt-lateral txt--rojo">Forma de retiro</p>
          </div>
          <div className="card card--check forma-retiro">
            <p className="card-title">Forma de retiro</p>
          </div>
          <div className="i-est">
            <div className="icono">
              <img src={Final} alt="Final" className="mobile" />
              <img src={FinalDesktop} alt="Final" className="desktop" />
            </div>
          </div>
          <div className="lateral-est">
            <p className="txt-lateral txt--blanco">Estado final</p>
          </div>
          <div className="card card--active card--slide14 card--sombra estado-final">
            <div className="card__header">
              <div>
                <p className="card-title">Estado final</p>
                <span className="--rotar arrow" />
              </div>
              <hr className="linea-separador" />
            </div>
            <div className="card__cuerpo card__cuerpo--slide14">
              <div className="container__sucursales">
                <p className="txt-input-medium">{finishText}</p>
                {data?.withdraw === 'cdlc' && (
                  <a
                    href="https://www.creditodelacasa.com.uy/institucional/puntos_atencion"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ver Sucursales
                  </a>
                )}
              </div>
              <div className="container__resumen-credito">
                <div>
                  <p>Resumen de tu crédito</p>
                </div>
                <div>
                  <p className="txt-input-medium txt--gris">Cédula de identidad</p>
                  <p className="txt-input-medium">{cedulaFormateada}</p>
                  <hr className="linea-separador" />
                </div>
                <div>
                  <p className="txt-input-medium txt--gris">Dinero solicitado</p>
                  <p className="txt-input-medium">$ {addFullstopOnThousands(creditData.amount)}</p>
                </div>
                <div>
                  <p className="txt-input-medium txt--gris">Cantidad de cuotas</p>
                  <p className="txt-input-medium">{creditData.installments}</p>
                </div>
                <div>
                  <p className="txt-input-medium txt--gris">Monto de cuota</p>
                  <p className="txt-input-medium">
                    $ {addFullstopOnThousands(creditData.installmentsAmount)}
                  </p>
                </div>
                <div>
                  <p className="txt-input-medium txt--gris">Vencimiento primera cuota</p>
                  <p className="txt-input-medium">{formattedDate}</p>
                </div>
                <div>
                  <hr className="linea-separador" />
                  <p className="txt-input-medium txt--gris-last">Forma de retiro</p>
                  <p className="txt-input-medium">{withdrawNameSelected}</p>
                  <br />
                  {data?.accountName && (
                    <>
                      <p className="txt-input-medium">{data.accountName.toUpperCase()}</p>
                      <p className="txt-input-medium">*****{data.accountNumber.slice(-4)}</p>
                      <p className="txt-input-medium">{data.bank.toUpperCase()}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card__separador">
              <hr className="linea-separador" />
            </div>
            <div className="card__botones">
              <button className="boton boton--active boton--primary" onClick={() => nextPage()}>
                Confirmar
              </button>
            </div>
          </div>
          <div className="lat-bot" />
          <div className="i-foot" />
          <div className="foot" />
        </div>
        <Footer />
      </>
    )
  );
};

export default Status;
