import axios from '../axios';

export const identity = async ({ files, documentData }) => {
  const response = await axios.post('/authenticate/validate-user-data', { files, documentData });
  return response.data || response;
};

export const identityAuthenticate = async ({ selfie, template, bestImage }) => {
  const response = await axios.post('/authentication/identity/authenticate', { selfie, template, bestImage });
  return response.data || response;
};
