import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useHistory, useLocation } from 'react-router-dom';
import CheckMobile from '../../assets/ico/mobile/aprobado.svg';
import CDLCLogo from '../../assets/img/desktop/CDLC-logo-white.svg';
import { handleInstructionsText } from '../../utils/withdrawal.helper';
import classes from './Final.module.css';

const tagManagerArgs = { dataLayer: { page: 'Final' }, dataLayerName: 'FinalDataLayer' };

const Final = () => {
  const history = useHistory();
  const location = useLocation();
  const validated = location.state.response.loggedIn || location.state.response.biometricalValidated;
  const withdraw = location.state.withdraw;

  const instructionsText = handleInstructionsText(validated, withdraw);

  TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    if (!location.state) history.push('/');
  }, [location.state, history]);

  return (
    !!location.state && (
      <div className={classes.MainDiv}>
        <img src={CheckMobile} alt="completado" />
        <h2>¡Listo, el efectivo es tuyo!</h2>
        <p>{instructionsText}</p>
        <button className={classes.Button} onClick={() => history.push({ pathname: '/', state: {} })}>
          Volver a inicio
        </button>
        <img src={CDLCLogo} alt="Logo CDLC blanco" className={classes.CDLCWhiteLogo} />
      </div>
    )
  );
};

export default Final;
