import { useEffect, useState } from 'react';
import OutlinedButton from '../UI/Buttons/OutlinedButton/OutlinedButton';
import RedButton from '../UI/Buttons/RedButton/RedButton';
import classes from './Accounts.module.css';

const Accounts = ({ hasAccounts, data, setData, isNewClient, nextPage, navigateBack, emailErrorVisible }) => {
  const [accountType] = useState('new');
  const [error] = useState(false);

  useEffect(() => {
    setData({
      ...data,
      accountNumber: data?.accountNumber ? data?.accountNumber : '',
      alias: data?.alias ? data?.alias : '',
      accountName: data?.accountName ? data?.accountName : '',
      email: data?.email ? data?.email : '',
      bank: data?.bank ? data?.bank : 'brou',
      currency: data?.currency ? data?.currency : 'uy',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAccount = (id) => {
    const account = document.getElementById(id);
    account.classList.toggle('cuenta-selected');
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    if (!error) {
      nextPage(e);
    }
  };

  return (
    <>
      {accountType === 'old' && hasAccounts && data.withdraw === 'cuenta' && (
        <>
          <div className={classes.BoxInput}>
            <p className={classes.TextMedium}>Selecciona la cuenta</p>
          </div>
          <div id="1" className={classes.Account} onClick={() => selectAccount('1')}>
            <p>Pesos Uruguayos</p>
            <p>Banco Santander</p>
            <p>************0000</p>
            <p>María Perez</p>
          </div>
          <div id="2" className={classes.Account} onClick={() => selectAccount('2')}>
            <p>Pesos Uruguayos</p>
            <p>BROU</p>
            <p>*****************0000</p>
            <p>María Perez</p>
          </div>
        </>
      )}
      {((accountType === 'new' && hasAccounts && data.withdraw === 'cuenta') ||
        (!hasAccounts && data.withdraw === 'cuenta') ||
        (isNewClient && data.withdraw === 'cuenta')) && (
        <>
          <form
            onSubmit={(e) => handleNextPage(e)}
            onKeyPress={(e) => {
              e.key === 'Enter' && handleNextPage(e);
            }}
          >
            {/* Todo lo de Cuenta Bancaria estaba hecho, se borró el código comentado en el PR #54 */}
            <div className={classes.ButtonDiv}>
              <OutlinedButton
                text="Volver"
                handleOnClick={navigateBack}
                type="button"
                customClasss={classes.GoBackButton}
              />
              <RedButton text="Continuar" />
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Accounts;
