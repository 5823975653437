import { useEffect, useState } from 'react';
import AppLogin from '../../components/AppLogin/AppLogin';
import Validacion from '../../components/Validation/Validation';

const OldClient = ({
  hasAccounts,
  hasWallet,
  data,
  setData,
  nextPage,
  navigateBack,
  emailErrorVisible,
  idNumberTooHigh,
  isValidated,
  setIsValidated,
  showSelphIdModal,
  setShowSelphIdModal,
  showSelphiModal,
  setShowSelphiModal,
  userHasRemainingAttempts,
  setUserHasRemainingAttempts,
}) => {
  const [showBiometricValidation, setShowBiometricValidation] = useState(
    !idNumberTooHigh && data.response.isUser && data.withdraw !== 'R' && userHasRemainingAttempts,
  );
  const [showLogin, setShowLogin] = useState(data.response.isAppUser);

  const handleRadio = (e) => {
    setData({
      ...data,
      withdraw: e.target.value,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      withdraw: data.withdraw ? data.withdraw : 'T',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <p className="txt-titulo-semi-hard-16">Elegí donde lo querés recibir</p>
      </div>
      <div className="container-radio">
        <div>
          <label className="box-radio" htmlFor="idCuenta">
            <input
              type="radio"
              id="idCuenta"
              value="T"
              name="getMmoney"
              onChange={handleRadio}
              checked={data.withdraw === 'T'}
            />
            <span className="check-radio"></span>
            <div>
              <p className="txt-input-medium-radio">En mi cuenta bancaria</p>
              <p className="txt-sub-input-medium">(La cuenta debe estar a tu nombre)</p>
            </div>
          </label>
        </div>
        {hasWallet && (
          <div>
            <label className="box-radio">
              <input
                type="radio"
                value="B"
                name="getMmoney"
                onChange={handleRadio}
                checked={data.withdraw === 'B'}
              />
              <span className="check-radio"></span>
              <div>
                <p className="txt-input-medium-radio">En mi Billetera Móvil</p>
              </div>
            </label>
          </div>
        )}
        <div>
          <label className="box-radio">
            <input
              type="radio"
              value="P"
              name="getMmoney"
              onChange={handleRadio}
              checked={data.withdraw === 'P'}
            />
            <span className="check-radio"></span>
            <div>
              <p className="txt-input-medium-radio">Redpagos</p>
              <p className="txt-sub-input-medium">
                <a
                  className="a-retiro"
                  href="https://www.redpagos.com.uy/#/search"
                  target="_blank"
                  rel="noreferrer"
                >
                  (Cualquier local de todo el país)
                </a>
              </p>
            </div>
          </label>
        </div>
        <div>
          <label className="box-radio">
            <input
              type="radio"
              value="R"
              name="getMmoney"
              onChange={handleRadio}
              checked={data.withdraw === 'R'}
            />
            <span className="check-radio"></span>
            <div>
              <p className="txt-input-medium-radio">Crédito de la Casa</p>
              <p className="txt-sub-input-medium">
                <a
                  className="a-retiro"
                  href="https://www.creditodelacasa.com.uy/institucional/puntos_atencion"
                  target="_blank"
                  rel="noreferrer"
                >
                  (Cualquier Sucursal o Punto de atención <br />
                  en todo el país)
                </a>
              </p>
            </div>
          </label>
        </div>
      </div>
      {showBiometricValidation && (
        <Validacion
          hasAccounts={hasAccounts}
          data={data}
          setData={setData}
          nextPage={nextPage}
          navigateBack={navigateBack}
          emailErrorVisible={emailErrorVisible}
          setUserHasRemainingAttempts={setUserHasRemainingAttempts}
          validated={isValidated}
          setIsValidated={setIsValidated}
          showSelphIdModal={showSelphIdModal}
          setShowSelphIdModal={setShowSelphIdModal}
          showSelphiModal={showSelphiModal}
          setShowSelphiModal={setShowSelphiModal}
        />
      )}
      {showLogin && (
        <AppLogin
          setShowBiometricValidation={setShowBiometricValidation}
          document={data.cedula}
          setShowLogin={setShowLogin}
        />
      )}
    </>
  );
};

export default OldClient;
