import React from 'react';
import Validacion from '../../components/Validation/Validation';

const NewClient = ({ withdraw, setWithdraw }) => {
  const handleRadio = (e) => {
    setWithdraw(e.target.value);
  };

  return (
    <>
      <p className="txt-input-medium">Para terminar necesitamos unos datos:</p>
      <div>
        <p className="txt-titulo-semi-hard-16">¿En dónde querés recibir tu dinero?</p>
      </div>
      <div className="container-radio">
        <div>
          <label className="box-radio">
            <input
              type="radio"
              value="cuenta"
              name="getMmoney"
              onChange={handleRadio}
              checked={withdraw === 'cuenta'}
            />
            <span className="check-radio"></span>
          </label>
          <div>
            <p className="txt-input-medium">En mi cuenta bancaria</p>
            <p className="txt-sub-input-medium">(La cuenta debe estar a tu nombre)</p>
          </div>
        </div>
        <div>
          <label className="box-radio">
            <input type="radio" value="Redpagos" name="getMmoney" onChange={handleRadio} />
            <span className="check-radio"></span>
          </label>
          <div>
            <p className="txt-input-medium">Redpagos</p>
            <p className="txt-sub-input-medium">
              <a
                className="a-retiro"
                href="https://www.redpagos.com.uy/#/search"
                target="_blank"
                rel="noreferrer"
              >
                (Cualquier local de todo el país)
              </a>
            </p>
          </div>
        </div>
        <div>
          <label className="box-radio">
            <input type="radio" value="cdlc" name="getMmoney" onChange={handleRadio} />
            <span className="check-radio"></span>
          </label>
          <div>
            <p className="txt-input-medium">Crédito de la Casa</p>
            <p className="txt-sub-input-medium">
              <a
                className="a-retiro"
                href="https://www.creditodelacasa.com.uy/institucional/puntos_atencion"
                targert="_blank"
              >
                (Cualquier Sucursal o Punto de atención <br />
                en todo el país)
              </a>
            </p>
          </div>
        </div>
      </div>
      {withdraw !== 'cdlc' && <Validacion withdraw={withdraw} isNewClient={true} />}
    </>
  );
};

export default NewClient;
