import clsx from 'clsx';
import { useState } from 'react';
import NoCap from '../../assets/ico/desktop/selphi-cap.svg';
import NoGlasses from '../../assets/ico/desktop/selphi-glasses.svg';
import FaceInCenter from '../../assets/ico/desktop/selphi-person.svg';
import Success from '../../assets/ico/mobile/check-circle-solid.svg';
import FacePhiSelphi from '../FacePhiSelphi/FacePhiSelphi';
import OutlinedButton from '../UI/Buttons/OutlinedButton/OutlinedButton';
import RedButton from '../UI/Buttons/RedButton/RedButton';
import classes from './SelphiModal.module.css';

const SelphiModal = ({ visible, showModal, idFiles, documentData, hasAttemptsLeft }) => {
  const [openModal, setOpenModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorData, setErrorData] = useState(undefined);

  const handleCloseFacephiModals = (authenticationSuccessful) => {
    setShowSuccessMessage(authenticationSuccessful);
    setTimeout(() => showModal(false, false, authenticationSuccessful), 5000);
  };

  const handleCancelRetry = () => showModal(false, false, false);

  const handleRetryValidation = () => showModal(true, false, false);

  return (
    visible && (
      <div className={classes.Overlay}>
        <div className={clsx(classes.MainDiv, openModal && classes.SephiModalHeight)}>
          <div className={classes.RightButton}>
            <button className={classes.CloseButton} onClick={() => showModal(false, false, false)}>
              X
            </button>
          </div>
          {!openModal ? (
            <div className={classes.ModalDiv}>
              <div className={classes.FacePhiInstructions}>
                <div className={classes.FacephiModalHeader}>
                  <h1>Captura de selfie</h1>
                  <p>Ubicá tu rostro en el centro del círculo y esperá, haremos la captura por vos.</p>
                </div>
                <div>
                  <div>
                    <div className={classes.Instructions}>
                      <p>Recordá ubicarte en un lugar con luz suficiente.</p>
                      <p>No utilices lentes, gorros ni accesorios</p>
                    </div>
                    <div className={classes.InstructionImagesSection}>
                      <img src={FaceInCenter} alt="Ponga la cara en el medio de la pantalla" />
                      <img src={NoGlasses} alt="No usar lentes" />
                      <img src={NoCap} alt="No usar gorra" />
                    </div>
                  </div>
                  <RedButton
                    type="button"
                    text="Continuar"
                    customClass={classes.ContinueButton}
                    isLoading={false}
                    handleOnClick={() => {
                      setTimeout(() => {
                        setOpenModal(true);
                      }, 1000);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : showSuccessMessage ? (
            <div className={classes.SuccessMessage}>
              <h1>La validación ha sido realizada con éxito</h1>
              <img src={Success} alt="Verificación realizada con éxito" />
            </div>
          ) : errorData ? (
            <div className={classes.ErrorDiv}>
              <h1>
                Ocurrió un error
                {hasAttemptsLeft
                  ? ', vuelva a intentar siguiendo las siguientes indicaciones.'
                  : ', lo sentimos, tendrá que realizar otro metodo de validación.'}
              </h1>
              <p>{errorData.message}</p>
              <div>
                {hasAttemptsLeft && (
                  <RedButton
                    text="Reintentar"
                    customClasss={classes.RetryButton}
                    handleOnClick={handleRetryValidation}
                  />
                )}
                <OutlinedButton text="Cancelar" handleOnClick={handleCancelRetry} />
              </div>
            </div>
          ) : (
            <FacePhiSelphi
              handleCloseFacephiModals={handleCloseFacephiModals}
              openModal={openModal}
              setOpenModal={setOpenModal}
              documentData={documentData}
              idFiles={idFiles}
              setErrorData={setErrorData}
            />
          )}
        </div>
      </div>
    )
  );
};

export default SelphiModal;
