import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CDLCLogo from '../../assets/img/desktop/CDLC-logo-white.svg';
import UpdatingLoader from '../../assets/lotties/lf30_editor_uiyofc3m.json';
import LottieConfig from '../../components/UI/LottieConfig/LottieConfig';
import classes from './Updating.module.css';

function Updating() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) history.push('/');
  }, [location.state, history]);

  return (
    !!location.state && (
      <div className={classes.MainDiv}>
        <LottieConfig animationData={UpdatingLoader} customHeight={140} customWidth={140} loop />
        <h2 className={classes.Title}>¡Estamos actualizando el sitio!</h2>
        <p className={classes.Text}>
          Por el momento no está disponible. Probá en unas horas o de lo contrario dirigite a la sucursal más
          cercana para solicitar tu crédito.
        </p>
        <a
          href="https://www.creditodelacasa.com.uy/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.Button}
        >
          Volver a sitio
        </a>
        <img src={CDLCLogo} alt="Logo CDLC blanco" className={classes.CDLCWhiteLogo} />
      </div>
    )
  );
}

export default Updating;
