import React from 'react';
import CDLCLogo from '../../../assets/img/desktop/CDLC_ROJO_SIN_FONDO.svg';
import classes from './Navbar.module.css';

function Navbar() {
  return (
    <div className={classes.Navbar}>
      <div className={classes.Logo}>
        <img src={CDLCLogo} alt="Logo CDLC" />
      </div>
    </div>
  );
}

export default Navbar;
