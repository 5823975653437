import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useHistory, useLocation } from 'react-router-dom';
import moneyDesktopIconPath from '../../assets/ico/desktop/icon-simulador-active.svg';
import dollarMobileIconPath from '../../assets/ico/mobile/dollar.svg';
import finalMobileIconPath from '../../assets/ico/mobile/final2.svg';
import withdrawalMobileIconPath from '../../assets/ico/mobile/retiro2.svg';
import moneyMobileIconPath from '../../assets/ico/mobile/simulador-active.svg';
import aboutYouMobileIconPath from '../../assets/ico/mobile/sobre-vos2.svg';
import SpinnerLoader from '../../assets/lotties/spinner-loader-white.json';
import '../../assets/styles.css';
import Modal from '../../components/SelectAmountModal/SelectAmountModal';
import Footer from '../../components/UI/Footer/Footer';
import LottieConfig from '../../components/UI/LottieConfig/LottieConfig';
import Navbar from '../../components/UI/Navbar/Navbar';
import { loanRequest } from '../../services/lead.service';
import { getPlan } from '../../services/plan.service.js';
import { paymentsHelper } from '../../utils/payments.helper';

export default function Layout({ simulatorParametersProp }) {
  const history = useHistory();
  const location = useLocation();
  const source = new URLSearchParams(location.search).get('4cKlmN');
  const campaign = new URLSearchParams(location.search).get('t56loW');
  const [simulatorParameters, setSimulatorParameters] = useState(
    location?.state?.simulatorParameters || simulatorParametersProp,
  );
  const data = location.state;
  const [amount, setAmount] = useState(
    data?.MontoElegido ? data.MontoElegido : simulatorParameters.montoDefault,
  );
  const [payments, setPayments] = useState(
    data?.CuotasElegidas ? data.CuotasElegidas : simulatorParameters.cuotasDefault,
  );
  const [perMonth, setPerMonth] = useState(Math.round((amount * 1.35) / payments));
  const [type, setType] = useState();
  const [visible, setVisible] = useState(false);
  const [oldDataRequestPlan, setOldDataRequestPlan] = useState({
    amount: '',
    fee: '',
    feeAmount: '',
    expire: '',
    minAmount: '',
    maxAmount: '',
  });
  const [awaitNextRequest, setAwaitNextRequest] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const handleIncreaseAmount = () => {
    if (amount < simulatorParameters.maxMonto) {
      setAmount((prevState) => parseInt(prevState) + 100);
    }
  };

  const handleDecreaseAmount = () => {
    if (amount > simulatorParameters.minMonto) {
      setAmount((prevState) => parseInt(prevState) - 100);
    }
  };

  const handleChangeAmount = (e) => {
    setAmount(Number(e.target.value));
  };

  const handleIncreasePayments = (value) => {
    if (payments < simulatorParameters.maxCuotas) {
      setPayments(value);
    }
  };

  const handleDecreasePayments = (value) => {
    if (payments > simulatorParameters.minCuotas) {
      setPayments(value);
    }
  };

  const handleChangePayments = (e) => {
    setPayments(paymentsHelper.getPaymentsValue(e.target.value));
  };

  const fetchPlanTimer = (newAmount, newPayments) => {
    setShowLoading(true);
    window.clearTimeout(awaitNextRequest);
    setAwaitNextRequest(window.setTimeout(async () => await fetchPlan(newAmount, newPayments), 1000));
  };

  const fetchPlan = async (newAmount, newPayments) => {
    const selectedAmount = newAmount || amount;
    const selectedPayment = newPayments || payments;
    if (oldDataRequestPlan.amount !== selectedAmount || oldDataRequestPlan.fee !== selectedPayment) {
      const plan = await getPlan(selectedAmount, selectedPayment);
      const doAdjustFee = !(plan.fee >= plan.minAmount && plan.fee <= plan.maxAmount);
      let fee = plan.fee;
      if (doAdjustFee) {
        if (fee <= plan.minAmount) {
          fee = plan.minAmount;
        }
        if (fee >= plan.maxAmount) {
          fee = plan.maxAmount;
        }
      }
      setOldDataRequestPlan({ ...plan });
      setSimulatorParameters({
        ...simulatorParameters,
        cuotasDefault: fee,
        diasPrimerCuota: plan.expire,
        maxCuotas: plan.maxAmount,
        minCuotas: plan.minAmount,
      });
      const payment = { target: { value: fee } };
      handleChangePayments(payment);
      setPerMonth(Math.round(plan.feeAmount));
      if (!doAdjustFee) {
        setShowLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      oldDataRequestPlan?.minAmount !== simulatorParameters?.minCuotas &&
      oldDataRequestPlan?.maxAmount !== simulatorParameters?.maxCuotas
    ) {
      oldDataRequestPlan?.fee !== payments && amount && payments
        ? fetchPlanTimer(amount, payments)
        : setShowLoading(false);
    } else {
      oldDataRequestPlan?.fee !== payments && fetchPlanTimer(amount, payments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldDataRequestPlan, payments, amount]);

  const dateToPayCout = moment(simulatorParameters.diasPrimerCuota).format('DD / MM / YYYY');

  const showModal = (type, visible) => {
    setType(type);
    setVisible(!visible);
  };

  const sendForm = (e, amount, payments, perMonth) => {
    e.preventDefault();
    const body = {
      amount: amount,
      installments: payments,
      installmentsAmount: perMonth,
      source,
      campaign,
    };
    return loanRequest(body)
      .then((res) => {
        history.push({
          pathname: `/about`,
          state: {
            ...data,
            uuid: res.data.data.uuid,
            isUser: res.data.data.isUser,
            isAppUser: res.data.data.isAppUser,
            MontoElegido: amount,
            CuotasElegidas: payments,
            MontoCuotas: perMonth,
            VencimientoPrimeraCuota: '20/09/1989',
            isNewClient: false,
            hasWallet: true,
            hasAccounts: true,
            noOffer: false,
            simulatorParameters,
          },
        });
      })
      .catch((error) => console.log(error.response.data.showMessage?.ES));
  };
  // TODO: AGREGAR ERROR CUANDO SE LLEGAN A 3 INTENTOS FALLIDOS

  useEffect(() => {
    localStorage.removeItem('validationGeneralError');
    localStorage.setItem('loginRetriesLeft', 3);
  }, []);

  if (simulatorParameters === {} || !amount) return null;

  return (
    <React.Fragment>
      <div>
        <Navbar />
        <div className="cuerpo cuerpo--slide1">
          <div className="lat-top" />
          <div className="i-linea">
            <div className="circulo" />
            <div className="linea linea--roja linea-1" />
          </div>
          <div className="titulo">
            <h2 className="title">Pedí tu efectivo ahora</h2>
          </div>
          <div className="i-sim">
            <div className="icono">
              <img src={moneyMobileIconPath} alt="Dinero" className="mobile" />
              <img src={moneyDesktopIconPath} alt="Dinero" className="desktop" />
            </div>
            <div className="linea linea--gris linea-2" />
          </div>
          <div className="lateral-sim">
            <p className="txt-lateral txt--blanco txt-lateral--active">Simulador</p>
          </div>
          <div className="card card--sombra card--active card--slide1 simulador">
            <div className="card__header">
              <div>
                <p className="card-title">Simulador</p>
                <span className="--rotar arrow" />
              </div>
              <hr className="linea-separador" />
            </div>
            <div className="simulador__slider">
              <div className="slider-range" id="slider-range1">
                <div className="prefix-container">
                  <p className="txt-medium">Quiero</p>
                  <p className="prefix">$</p>
                </div>
                <input
                  type="text"
                  value={amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  className="input-label input-label--moneda"
                  id="input-moneda"
                  onClick={() => showModal('amount', visible)}
                  readOnly
                />
                <input
                  type="button"
                  value="-"
                  className="boton--primary boton-slider"
                  onClick={() => {
                    handleDecreaseAmount();
                    fetchPlanTimer(amount - 100);
                  }}
                />
                <p className="txt-slider">$ {simulatorParameters.minMonto?.toLocaleString('de-DE')}</p>
                <div
                  className="grad range-slider"
                  id="box-slider1"
                  style={{
                    '--min': simulatorParameters.minMonto,
                    '--max': simulatorParameters.maxMonto,
                    '--step': 500,
                    '--value': amount,
                    '--text-value': 1000,
                    '--prefix': '$',
                  }}
                >
                  <input
                    type="range"
                    value={amount}
                    symbol="$"
                    id="slider1"
                    max={simulatorParameters.maxMonto}
                    min={simulatorParameters.minMonto}
                    step="100"
                    name="slider1"
                    onChange={handleChangeAmount}
                    onMouseUp={() => fetchPlanTimer()}
                    onPointerUp={() => fetchPlanTimer()}
                    onTouchEnd={() => fetchPlanTimer()}
                  />
                  <div className="range-slider__progress" />
                </div>
                <p className="txt-slider">$ {simulatorParameters.maxMonto?.toLocaleString('de-DE')}</p>
                <input
                  type="button"
                  value="+"
                  className="boton--primary boton-slider"
                  onClick={() => {
                    handleIncreaseAmount();
                    fetchPlanTimer(amount + 100);
                  }}
                />
              </div>
              <div className="slider-range">
                <p className="txt-medium">¿En cuántas cuotas?</p>
                <p className="input-label-payments" onClick={() => showModal('payments', visible)}>
                  {payments}
                </p>
                <input
                  type="button"
                  value="-"
                  className="boton--primary boton-slider"
                  onClick={() => {
                    const value = paymentsHelper.decreasePayments(payments);
                    handleDecreasePayments(value);
                    fetchPlanTimer(amount, value);
                  }}
                />
                <p className="txt-slider">{simulatorParameters.minCuotas}</p>
                <div
                  className="grad range-slider"
                  id="box-slider2"
                  style={{
                    '--min': simulatorParameters.minCuotas,
                    '--max': simulatorParameters.maxCuotas,
                    '--step': 1,
                    '--value': payments,
                    '--text-value': '1',
                  }}
                >
                  <input
                    type="range"
                    value={payments}
                    id="slider2"
                    max={simulatorParameters.maxCuotas}
                    min={simulatorParameters.minCuotas}
                    step="1"
                    name="slider2"
                    onChange={handleChangePayments}
                    onMouseUp={() => fetchPlanTimer()}
                    onPointerUp={() => fetchPlanTimer()}
                    onTouchEnd={() => fetchPlanTimer()}
                  />
                  <div className="range-slider__progress" />
                </div>
                <p className="txt-slider">{simulatorParameters.maxCuotas}</p>
                <input
                  type="button"
                  value="+"
                  className="boton--primary boton-slider"
                  onClick={() => {
                    const value = paymentsHelper.increasePayments(payments);
                    handleIncreasePayments(value);
                    fetchPlanTimer(amount, value);
                  }}
                />
              </div>
            </div>
            <div className="simulador__img simulador__img--degradado">
              <p className="txt-medium">Tu cuota por mes es de*</p>
              <SkeletonTheme baseColor="#ec1f39" highlightColor="#da8985">
                <p>
                  $ {showLoading ? <Skeleton height={23} width={100} /> : perMonth.toLocaleString('de-DE')}
                </p>
              </SkeletonTheme>
              <img src={dollarMobileIconPath} alt="Dinero" />
            </div>
            <div className="simulador__cuota">
              <p className="txt-medium">Pagá tu primera cuota el</p>
              <p>{dateToPayCout}</p>
            </div>
            <div className="installments_disclaimer_text">
              <p>
                * El monto a pagar por mes es aproximado. El monto exacto lo verás luego de verificar tu
                identidad.
              </p>
            </div>
            <div className="card__separador">
              <hr className="linea-separador" />
            </div>
            <div className="card__botones">
              <button
                onClick={(e) => sendForm(e, amount, payments, perMonth)}
                disabled={showLoading}
                className="boton--primary boton"
              >
                <div className="align-lottie-btn">
                  Continuar
                  {showLoading && (
                    <LottieConfig animationData={SpinnerLoader} customHeight={20} customWidth={20} loop />
                  )}
                </div>
              </button>
            </div>
          </div>
          <div className="i-sob">
            <div className="icono icono--desactivado">
              <img src={aboutYouMobileIconPath} alt="Sobre tí" />
            </div>
            <div className="linea linea--gris linea-3" />
          </div>
          <div className="lateral-sob">
            <p className="txt-lateral txt--gris">Sobre vos</p>
          </div>
          <div className="card card--sombra card--disabled sobre-vos">
            <p>Sobre vos</p>
          </div>
          <div className="i-forma">
            <div className="icono icono--desactivado">
              <img src={withdrawalMobileIconPath} alt="Retiro" />
            </div>
            <div className="linea linea--gris linea-3" />
          </div>
          <div className="lateral-forma">
            <p className="txt-lateral txt--gris">Forma de retiro</p>
          </div>
          <div className="card card--sombra card--disabled forma-retiro">
            <p>Forma de retiro</p>
          </div>
          <div className="i-est">
            <div className="icono icono--desactivado">
              <img src={finalMobileIconPath} alt="Final" />
            </div>
          </div>
          <div className="lateral-est">
            <p className="txt-lateral txt--gris">Estado final</p>
          </div>
          <div className="card card--sombra card--disabled estado-final">
            <p>Estado final</p>
          </div>
          <div className="lat-bot" />
          <div className="i-foot" />
          <div className="foot" />
        </div>
        <Footer />
      </div>
      {visible && (
        <Modal
          type={type}
          setVisible={setVisible}
          amount={amount}
          setAmount={setAmount}
          payments={payments}
          setPayments={setPayments}
          fetchPlanTimer={fetchPlanTimer}
          simulatorParameters={simulatorParameters}
        />
      )}
    </React.Fragment>
  );
}
