import axios from '../axios';

export const login = async ({ document, password }) => {
  const token = await axios.post('/login', {
    document,
    password,
  });

  if (token.status === 200) {
    localStorage.setItem('appToken', token.data.data.token);
    return { successfulLogin: true };
  } else {
    return { successfulLogin: false, message: token.data.message };
  }
};
