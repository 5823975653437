import clsx from 'clsx';
import classes from './CustomSwitch.module.css';

function CustomSwitch({ customClass, label, value, setValue, name }) {
  return (
    <div className={clsx(classes.MainDiv, customClass)}>
      <label className={classes.Switch}>
        <input
          id={name}
          type="checkbox"
          className={classes.Input}
          value={value}
          checked={value}
          onClick={() => setValue(!value)}
        />
        <span className={classes.Slider} />
      </label>
      <label htmlFor={name} className={classes.Label}>
        {label}
      </label>
    </div>
  );
}

export default CustomSwitch;
