import { useState } from 'react';
import InputMask from 'react-input-mask';
import clsx from 'clsx';
import classes from './PhoneNumberField.module.css';

const PhoneNumberField = ({ onChange, ...props }) => {
  const getRawInput = (input) => input.replace(/[^0-9]/g, '').toUpperCase();
  const getInitialMask = () => '999 999 999';
  const [mask, setMask] = useState(getInitialMask(''));

  const onValueChange = (event) => {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: getRawInput(event.target.value),
      },
    });
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    const value = getRawInput(oldState.value);
    if (value.length === 8 && value.match(/^\d+$/) && userInput) {
      setMask('999 999 999');
      return {
        selection: { start: 12, end: 12 },
        value: value + userInput,
      };
    }
    return newState;
  };

  return (
    <>
      <InputMask
        {...props}
        className={clsx(classes.Input, classes.InputMargin)}
        placeholder="099 123 456"
        mask={mask}
        onChange={onValueChange}
        beforeMaskedValueChange={beforeMaskedValueChange}
      />
    </>
  );
};

export default PhoneNumberField;
