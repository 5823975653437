import { FPhi as FPhiSelphi } from '@facephi/selphi-widget-web';
import { FPhi as FPhiSelphID } from '@facephi/selphid-widget-web';
export const resourcesPathSelphID = `${process.env.REACT_APP_FACEPHI_RESOURCES_PATH}selphid`;

export const resourcesPathSelphi = `${process.env.REACT_APP_FACEPHI_RESOURCES_PATH}selphi.Bundle`;

export const cameraResolutions = {
  res480p: { title: '640x480', width: 640, height: 480 },
  res600p: { title: '800x600', width: 800, height: 600 },
  res768p: { title: '1024x768', width: 1024, height: 768 },
  res720p: { title: '1280x720 (720p)', width: 1280, height: 720 },
  res1080p: { title: '1920x1080 (1080p)', width: 1920, height: 1080 },
};

export const turnOffCamera = () => {
  navigator.mediaDevices.getUserMedia({ video: true }).then((mediaStream) => {
    const tracks = mediaStream.getTracks();
    tracks.forEach((track) => {
      track.enabled = false;
      track.stop();
    });
  });
};

export const moduleLoaded = (type, eventData) => {
  if (type === 'selphID') console.log('SelphId: onModuleLoaded() => eventData', eventData);
  else console.log('Selphi: onModuleLoaded() => eventData', eventData);
};

export const stabilizing = (stabilizingResult) => {
  console.log('Selphi: onStabilizing() => stabilizingResult', stabilizingResult);
};

export const timeoutErrorButtonClick = (onStopCapture) => {
  console.log('Selphi: onTimeoutErrorButtonClick()');
  onStopCapture();
};

export const trackStatus = (type, eventData, showLog) => {
  if (showLog) {
    const isSelphID = type === 'selphID';
    const tracks = isSelphID ? FPhiSelphID.SelphID.TrackStatus : FPhiSelphi.Selphi.TrackStatus;
    const trackEntries = Object.entries(tracks);
    const status = trackEntries.find((event) => event[1] === eventData.detail.code);
    if (!!status) {
      const codeAndTimeStamp = `Code ${status[1]} ${status[0]}. TimeStamp ${eventData.detail.timeStamp}`;
      if (isSelphID) console.log('SelphId: onTrackStatus() => eventData', eventData, codeAndTimeStamp);
      else console.log('Selphi: onTrackStatus() => eventData', eventData, codeAndTimeStamp);
    }
  }
};

export const generateDocumentFiles = (extractionResult) => {
  const frontDocumentBase64 = extractionResult.detail.images.frontDocument;
  const backDocumentBase64 = extractionResult.detail.images.backDocument;
  const frontDocument = frontDocumentBase64.split('base64,').slice(-1)[0];
  const backDocument = backDocumentBase64.split('base64,').slice(-1)[0];
  const documentFiles = [
    { content: frontDocument, type: 1, detalle: 'frontDocument' },
    { content: backDocument, type: 2, detalle: 'backDocument' },
  ];
  return documentFiles;
};

export const generateSelphiBestImage = (extractionResult) => {
  const bestImageBase64 = extractionResult?.detail?.bestImage?.src;
  const bestImage = bestImageBase64.split('base64,').slice(-1)[0];
  return bestImage;
};

export const generateIdentityValues = (bestImage, templateRaw, idImages, document, bestImageTokenized) => {
  const selfieFile = { content: bestImage, type: 3, detalle: 'bestImage' };
  const template = { content: templateRaw, type: 4, detalle: 'templateRaw' };
  const selfieTokenizedFile = { content: bestImageTokenized, type: 5, detalle: 'bestImageTokenized' };

  const files = !!idImages
    ? [...idImages, selfieFile, template, selfieTokenizedFile]
    : [selfieFile, template, selfieTokenizedFile];
  const documentData = !!idImages ? { ...document, documentType: +document?.documentType } : null;
  const mutationValues = { files, documentData };
  return mutationValues;
};

export const generateIdentityAuthenticateValues = (bestImage, templateRaw, bestImageTokenized) => {
  const mutationValues = { selfie: bestImage, template: templateRaw, bestImage: bestImageTokenized };
  return mutationValues;
};

export const handleError = (message, setErrorData) => {
  setErrorData({
    open: true,
    code: 200,
    message,
  });
};

export const identityHandler = (
  result,
  handleError,
  setErrorData,
  validationGeneralError,
  message,
  handleCloseFacephiModals,
  setShowSpinner,
) => {
  if (result) {
    handleCloseFacephiModals(true);
  } else {
    localStorage.setItem('validationGeneralError', `${validationGeneralError + 1}`);
    if (validationGeneralError + 1 < 3) {
      setErrorData({
        open: true,
        code: 200,
        message: message,
      });
    } else {
      const userMessage = message ? message : 'Lamentablemente por temas de seguridad no podemos avanzar.';
      handleError(userMessage, setErrorData);
    }
    setShowSpinner(false);
  }
};
