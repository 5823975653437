import axios from '../axios';

export const getStates = async () => {
  const response = await axios.get('/location/states');
  return response.data.data;
};

export const getCities = async (stateId) => {
  const response = await axios.get(`/location/cities/${stateId}`);
  return response.data.data;
};
