/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import RetiroActiveDesktop from '../../assets/ico/desktop/icon-retiro-active-desktop.svg';
import SimuladorCheckDesktop from '../../assets/ico/desktop/icon-simulador-check-desktop.svg';
import SobreVosCheckDesktop from '../../assets/ico/desktop/icon-sobrevos-check-desktop.svg';
import Coins from '../../assets/ico/mobile/coins.svg';
import Final from '../../assets/ico/mobile/final2.svg';
import RetiroActive from '../../assets/ico/mobile/retiro-active.svg';
import SimuladorCheckMobile from '../../assets/ico/mobile/simulador-check.svg';
import SobreVosCheckMobile from '../../assets/ico/mobile/sobre-vos-check.svg';
import SpinnerLoader from '../../assets/lotties/spinner-loader-white.json';
import Footer from '../../components/UI/Footer/Footer';
import LottieConfig from '../../components/UI/LottieConfig/LottieConfig';
import Navbar from '../../components/UI/Navbar/Navbar';
import { withdrawal } from '../../services/about.service';
import { addFullstopOnThousands } from '../../utils/number.helper';
import NewClient from './NewClient';
import OldClient from './OldClient';

const Credit = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({ ...location.state });
  const [emailErrorVisible, setEmailErrorVisible] = useState(false);
  const [alternativeAcepted, setAlternativeAcepted] = useState(false);
  const [alternativeRejected, setAlternativeRejection] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [showSelphIdModal, setShowSelphIdModal] = useState(false);
  const [showSelphiModal, setShowSelphiModal] = useState(false);
  const [userHasRemainingAttempts, setUserHasRemainingAttempts] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const isNewClient = data?.isNewClient;
  const hasAlternative = data?.response?.betterOfferAmount;
  const hasWallet = data?.response?.hasWallet;
  const hasAccounts = data?.hasAccounts;
  const idNumberTooHigh = +data.cedula > 60000000;
  const token = localStorage.getItem('token');

  const rejectAlternative = () => setAlternativeRejection(true);

  const updateLoan = () => setAlternativeAcepted(true);

  const fetchWithdrawal = async () => {
    setIsLoading(true);
    let newBody = {
      leadUuid: data?.response?.lead?.uuid,
      identificationDocument: data.cedula,
      withdrawal: data.withdraw,
      acceptBetterOffer: alternativeAcepted,
      token,
      phone: data.celular,
    };
    const withdrawalResponse = await withdrawal(newBody);
    if (withdrawalResponse.data.data.mensajeNro === 0) {
      history.push({
        pathname: '/status',
        state: {
          ...data,
          response: {
            ...data.response,
            betterOfferAccepted: alternativeAcepted,
            lead: { ...data.response.lead, betterOfferAccepted: alternativeAcepted },
          },
        },
      });
    }
    setIsLoading(false);
    return withdrawalResponse;
  };

  const nextPage = (e) => {
    if (!isValidated && userHasRemainingAttempts && data.withdraw !== 'R') {
      setShowSelphIdModal(true);
    } else if (data.email && !emailRegex.test(data.email)) {
      e.preventDefault();
      setEmailErrorVisible(true);
    } else {
      e.preventDefault();
      setEmailErrorVisible(false);

      fetchWithdrawal();
    }
  };

  const navigateBack = () => {
    if (data.response.isUser) {
      history.push({ pathname: '/about', state: data });
    } else {
      history.push({ pathname: '/additionalInformation', state: data });
    }
  };

  useEffect(() => {
    if (!location.state) history.push('/');
  }, []);

  return (
    !!location.state && (
      <>
        <Navbar />
        <div className="cuerpo cuerpo--slide3">
          <div className="lat-top" />
          <div className="i-linea">
            <div className="circulo" />
            <div className="linea linea--roja linea-1" />
          </div>
          <div className="titulo">
            <h2 className="title">¡Tu crédito está casi listo!</h2>
          </div>
          <div className="i-sim">
            <div className="icono">
              <img src={SimuladorCheckMobile} alt="Simulador" className="mobile" />
              <img src={SimuladorCheckDesktop} alt="Simulador" className="desktop" />
            </div>
            <div className="linea linea--roja linea-2 linea-2--slide2" />
          </div>
          <div className="lateral-sim">
            <p className="txt-lateral txt--rojo">Simulador</p>
          </div>
          <div className="card card--check simulador">
            <p className="card-title">Simulador</p>
            <span className="--rotar check" />
          </div>
          <div className="i-sob">
            <div className="icono">
              <img src={SobreVosCheckMobile} alt="Sobre vos" className="mobile" />
              <img src={SobreVosCheckDesktop} alt="Sobre vos" className="desktop" />
            </div>
            <div className="linea linea--roja linea-3 linea-3--slide3" />
          </div>
          <div className="lateral-sob">
            <p className="txt-lateral txt--rojo">Sobre vos</p>
          </div>
          <div className="card card--check sobre-vos">
            <p className="card-title">Sobre vos</p>
            <span className="--rotar check" />
          </div>
          <div className="i-forma">
            <div className="icono">
              <img src={RetiroActive} alt="Retiro" className="mobile" />
              <img src={RetiroActiveDesktop} alt="Retiro" className="desktop" />
            </div>
            <div className="linea linea--gris linea-4 linea-4--slide3" />
          </div>
          <div className="lateral-forma">
            <p className="txt-lateral txt--blanco txt-lateral--active">Forma de retiro</p>
          </div>
          <div className="card card--sombra card--active card--slide3 forma-retiro">
            <div className="card__header">
              <div>
                <p className="card-title">Forma de retiro</p>
                <span className="--rotar arrow" />
              </div>
              <hr className="linea-separador" />
            </div>
            <div className="card__cuerpo card__cuerpo--slide3">
              {hasAlternative && !alternativeRejected && !alternativeAcepted && (
                <div className="grid-cuotas">
                  <div className="recuadro-cuotas">
                    <p className="txt-medium">
                      Elegiste ${addFullstopOnThousands(data.response.offerAmount)}
                    </p>
                    <p>
                      En {data.response.offerInstallments} cuotas <br />
                      de ${addFullstopOnThousands(data.response.offerInstallment)}
                    </p>
                  </div>
                  <div className="img-degradado img-degradado--slide8">
                    <p className="txt-input-medium txt--blanco">
                      Pero podés llevarte ${addFullstopOnThousands(data.response.betterOfferAmount)}
                    </p>
                    <p>
                      En {data.response.betterOfferInstallements} cuotas <br />
                      de ${addFullstopOnThousands(data.response.betterOfferInstallmentAmount)}
                    </p>
                    <button onClick={() => updateLoan()} className="boton">
                      ¡Sí, lo quiero!
                    </button>
                    <button id="reject" onClick={rejectAlternative}>
                      Prefiero mi opción inicial
                    </button>
                    <img src={Coins} alt="Coins" className="coins" />
                  </div>
                </div>
              )}
              {((hasAlternative && alternativeRejected) || !hasAlternative) && (
                <div className="img-degradado img-degradado--slide3">
                  <p className="txt-medium">
                    Te llevas ${addFullstopOnThousands(data?.response?.offerAmount)}
                  </p>
                  <p>
                    En {data?.response?.offerInstallments} cuotas <br />
                    de ${addFullstopOnThousands(data?.response?.offerInstallment)}
                    <br />
                  </p>
                  {data?.response?.offerAmount < data?.MontoElegido && (
                    <p className="aclaration-credit-message">
                      * Pediste ${addFullstopOnThousands(data?.MontoElegido)}, pero podemos otorgarte $
                      {addFullstopOnThousands(data?.response?.offerAmount)}
                    </p>
                  )}
                  <img src={Coins} alt="Coins" className="coins" />
                </div>
              )}
              {alternativeAcepted && (
                <div className="img-degradado img-degradado--slide3">
                  <p className="txt-medium">
                    Te llevas ${addFullstopOnThousands(data.response.betterOfferAmount)}
                  </p>
                  <p>
                    En {data.response.betterOfferInstallements} cuotas <br />
                    de ${addFullstopOnThousands(data.response.betterOfferInstallmentAmount)}
                  </p>
                  <img src={Coins} alt="Coins" className="coins" />
                </div>
              )}
              {isNewClient ? (
                <NewClient
                  hasAccounts={hasAccounts}
                  hasWallet={hasWallet}
                  data={data}
                  setData={setData}
                  nextPage={nextPage}
                  navigateBack={navigateBack}
                  emailErrorVisible={emailErrorVisible}
                />
              ) : (
                <OldClient
                  hasAccounts={hasAccounts}
                  hasWallet={hasWallet}
                  data={data}
                  setData={setData}
                  nextPage={nextPage}
                  navigateBack={navigateBack}
                  emailErrorVisible={emailErrorVisible}
                  idNumberTooHigh={idNumberTooHigh}
                  isValidated={isValidated}
                  setIsValidated={setIsValidated}
                  showSelphIdModal={showSelphIdModal}
                  setShowSelphIdModal={setShowSelphIdModal}
                  showSelphiModal={showSelphiModal}
                  setShowSelphiModal={setShowSelphiModal}
                  userHasRemainingAttempts={userHasRemainingAttempts}
                  setUserHasRemainingAttempts={setUserHasRemainingAttempts}
                />
              )}
              {data.withdraw !== 'cuenta' && (
                <div className="card__botones">
                  <button className="boton boton--outline-primary boton--vovler" onClick={navigateBack}>
                    Volver
                  </button>
                  <button className="boton boton--primary" onClick={nextPage} disabled={isLoading}>
                    <div className="align-lottie-btn">
                      Continuar
                      {isLoading && (
                        <LottieConfig animationData={SpinnerLoader} customHeight={20} customWidth={20} />
                      )}
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="i-est">
            <div className="icono">
              <img src={Final} alt="Final" />
            </div>
          </div>
          <div className="lateral-est lateral-final-status">
            <p className="txt-lateral txt--gris">Estado final</p>
          </div>
          <div className="card card--sombra card--disabled estado-final">
            <p>Estado final</p>
          </div>
          <div className="lat-bot" />
          <div className="i-foot" />
          <div className="foot" />
        </div>
        <Footer />
      </>
    )
  );
};

export default Credit;
