import { FPhi } from '@facephi/selphid-widget-web';
import { createRef, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { identity } from '../../services/facephi.service';
import {
  cameraResolutions,
  generateDocumentFiles,
  handleError,
  identityHandler,
  moduleLoaded,
  resourcesPathSelphID as resourcesPath,
  trackStatus,
  turnOffCamera,
} from '../../utils/facephi.helper';
import classes from '../SelphIdModal/SelphIdModal.module.css';

const FacePhiSelphID = ({
  openModal,
  setOpenModal,
  originPage,
  nextPages,
  justDocument,
  selfie,
  template,
  documentData,
  cashStepC,
  handleEndExtracionCorrectly,
  setIdFiles,
}) => {
  const history = useHistory();
  const widgetRef = createRef();
  const isComponentMounted = useRef();

  const [isWidgetCaptureStarted, setIsWidgetCaptureStarted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorData, setErrorData] = useState({
    open: false,
    message: '',
    action: undefined,
    code: undefined,
  });

  isComponentMounted.current = true;
  const showLog = process.env.REACT_APP_ENV_NAME !== 'prod';

  const documentExceptions = +localStorage.getItem('documentExceptions');
  const documentCancellations = +localStorage.getItem('documentCancellations');
  const documentCaptureErrors = +localStorage.getItem('documentCaptureErrors');
  const documentGeneralErrors = +localStorage.getItem('documentGeneralErrors');

  const selfieCaptureErrors = +localStorage.getItem('selfieCaptureErrors');
  const selfieGeneralErrors = +localStorage.getItem('selfieGeneralErrors');

  useEffect(() => {
    if (openModal) {
      onStartCapture();
      if (isWidgetCaptureStarted) {
        const node = widgetRef.current;
        if (!!node) {
          node.addWidgetEventListener('onModuleLoaded', onModuleLoaded);
          node.addWidgetEventListener('onExtractionFinished', onExtractionFinished);
          node.addWidgetEventListener('onUserCancelled', onUserCancelled);
          node.addWidgetEventListener('onExceptionCaptured', onExceptionCaptured);
          node.addWidgetEventListener('onExtractionTimeout', onExtractionTimeout);
          node.addWidgetEventListener('onTrackStatus', onTrackStatus);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetRef, isWidgetCaptureStarted, openModal]);

  const [identitytMutation] = useMutation(identity, {
    onSettled: async (response, error, values) => await mutationHandler(response, error, values),
  });

  const mutationHandler = async (response, error, values) => {
    onStopCapture();
    await identityHandler(
      response,
      error,
      originPage,
      nextPages,
      cashStepC,
      history,
      handleError,
      setErrorData,
      setOpenModal,
      setShowSpinner,
      documentCaptureErrors,
      documentGeneralErrors,
      selfieCaptureErrors,
      selfieGeneralErrors,
      identitytMutation,
      values,
      documentData,
    );
  };

  const onStartCapture = async () => {
    console.log('SelphId: onStartCapture() => isWidgetCaptureStarted', isWidgetCaptureStarted);
    const checkCapabilities = await FPhi.SelphID.CheckCapabilities();
    console.log('SelphId: checkCapabilities', checkCapabilities);
    const isCapableDevice = checkCapabilities.video_devices && checkCapabilities.wasm;

    if (!isCapableDevice) {
      const response = {
        message: 'Este dispositivo no puede ejecutar FacePhi. Intente nuevamente con otro.',
      };
      onStopCapture();
      handleError(response.message, response, setErrorData, history);
    } else {
      console.log('SelphId: Device capabilities are OK');
      setIsWidgetCaptureStarted(true);
    }
  };

  const onStopCapture = () => {
    turnOffCamera();
    setIsWidgetCaptureStarted(false);
    setIsWidgetCaptureStarted((state) => {
      console.log('SelphId: onStopCapture() => isWidgetCaptureStarted', state);
      return state;
    });
  };

  const onModuleLoaded = (eventData) => moduleLoaded('selphID', eventData);

  const onEndExtraction = async (files) => {
    console.log('SelphId: onEndExtraction() => files', files);
    setIdFiles(files);
    handleEndExtracionCorrectly(files);
  };

  const onExtractionFinished = async (extractionResult) => {
    setShowSpinner(true);
    let extractions = [{ detalle: 'ExtractionResult event', content: extractionResult }];
    for (let index = 0; index < extractionResult.detail.images.length; index++) {
      const image = extractionResult.detail.images[index];
      extractions = [...extractions, { detalle: `Image with index ${index}`, content: image.src }];
    }
    console.log('SelphId: onExtractionFinished()', extractions);
    const files = generateDocumentFiles(extractionResult);
    await onEndExtraction(files);
  };

  const onUserCancelled = () => {
    console.log('SelphId: onUserCancelled() => isWidgetCaptureStarted', isWidgetCaptureStarted);
    localStorage.setItem('documentCancellations', `${documentCancellations + 1}`);
    setOpenModal(false);
    onStopCapture();
  };

  const onExceptionCaptured = (exceptionResult) => {
    console.log('SelphId: onExceptionCaptured() => exceptionResult', exceptionResult);
    console.log('SelphId: onExceptionCaptured() => exception message', exceptionResult.detail.message);
    localStorage.setItem('documentExceptions', `${documentExceptions + 1}`);
    const response = { message: 'Ocurrió una excepción. Volvé a intentar' };
    onStopCapture();
    const selphIdExceptions = +localStorage.getItem('documentExceptions');
    if (selphIdExceptions < 3) handleError('reload', response, setErrorData, history);
    else handleError('restartFromSelphId', response, setErrorData, history);
  };

  const onExtractionTimeout = (eventInfo) => {
    console.log('SelphId: onExtractionTimeout() => eventInfo', eventInfo);
    const response = { message: 'Excediste el límite de tiempo. Volvé a intentar' };
    onStopCapture();
    handleError('restartFromSelphId', response, setErrorData, history);
  };

  const onTrackStatus = (eventData) => trackStatus('selphID', eventData, showLog);

  return (
    openModal && (
      <div className={classes.FacePhiSelphIdModalDiv} style={{ minHeight: '665px !important' }}>
        {showSpinner ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <div className={classes.FacePhiSelphIdHeader}>
              <h1>Validá tu identidad</h1>
              <p>Ubicá tu cédula dentro del recuadro blanco y esperá, haremos una captura por vos</p>
            </div>
            <div style={{ width: '100%', height: '70%' }}>
              <facephi-selphid
                style={{ minHeight: '450px', maxWidth: '837px', width: '100%', backgroundColor: '#f4f7fd' }} // minHeight: 550
                className={classes.Facephi}
                language="es"
                ref={widgetRef}
                bundlePath={resourcesPath}
                // resourcesPath={resourcesPath + '/FPhi.Widget.Resources'}
                // graphPath={resourcesPath + '/graph.xml'}
                licenseKey={process.env.REACT_APP_FACEPHI_LICENSE_KEY}
                accessibility={false}
                accessibleElements={['button', 'buttonImage']}
                imageFormat="image/png"
                imageQuality={1.0}
                blurredThreshold={0.5} // de 0.1 a 0.3
                widgetCameraResolution="res720p"
                cameraWidth={cameraResolutions.res720p.width}
                cameraHeight={cameraResolutions.res720p.height}
                cameraMirror={true}
                canvasHD={true}
                edgeOffset={null}
                forceLandscape={false}
                cropFactor={1.0}
                documentDimensions={{ x: 0, y: 0, width: 85.6, height: 53.98 }}
                documentAspectRatio={85.6 / 53.98}
                documentMode={1} // 0 = SingleSide, 1 = DoubleSide
                externalCamera={false}
                cameraOverflow={true}
                captureTimeout={30.0}
                captureRetries={3}
                previewCapture={true}
                tutorial={true}
                initialTip={false}
                askSimpleMode={false}
                startSimpleMode={false}
                debugMode={false}
                showLog={showLog}
                status_height={50}
                videoRecord={false}
                videoRecordRate={3.0}
                videoRecordScale={cameraResolutions.res720p.width < 1280 ? 1 : 0.5}
                videoRecordType={FPhi.SelphID.RecorderType.Remote}
              />
            </div>
          </>
        )}
      </div>
    )
  );
};

export default FacePhiSelphID;
