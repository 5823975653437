import { FPhi } from '@facephi/selphi-widget-web';
import { createRef, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import SelphiLoading from '../../assets/img/desktop/SelphiLoading.gif';
import { identity } from '../../services/facephi.service';
import {
  cameraResolutions,
  generateIdentityValues,
  generateSelphiBestImage,
  handleError,
  identityHandler,
  moduleLoaded,
  resourcesPathSelphi as resourcesPath,
  stabilizing,
  timeoutErrorButtonClick,
  trackStatus,
  turnOffCamera,
} from '../../utils/facephi.helper';
import classes from '../SelphiModal/SelphiModal.module.css';

const FacePhiSelphi = ({
  handleCloseFacephiModals,
  openModal,
  setOpenModal,
  documentData,
  idFiles,
  setErrorData,
}) => {
  const history = useHistory();
  const widgetRef = createRef();
  const isComponentMounted = useRef();
  const [isWidgetCaptureStarted, setIsWidgetCaptureStarted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  isComponentMounted.current = true;
  const showLog = process.env.REACT_APP_ENV_NAME !== 'prod';

  const selfieTimeouts = +localStorage.getItem('selfieTimeouts');
  const selfieExceptions = +localStorage.getItem('selfieExceptions');
  const selfieCancellations = +localStorage.getItem('selfieCancellations');
  const validationGeneralError = +localStorage.getItem('validationGeneralError');

  useEffect(() => {
    if (openModal) {
      onStartCapture();
      if (isWidgetCaptureStarted) {
        const node = widgetRef.current;
        if (!!node) {
          node.addWidgetEventListener('onModuleLoaded', onModuleLoaded);
          node.addWidgetEventListener('onStabilizing', onStabilizing);
          node.addWidgetEventListener('onExtractionFinish', onExtractionFinish);
          node.addWidgetEventListener('onUserCancel', onUserCancel);
          node.addWidgetEventListener('onExceptionCaptured', onExceptionCaptured);
          node.addWidgetEventListener('onExtractionTimeout', onExtractionTimeout);
          node.addWidgetEventListener('onTimeoutErrorButtonClick', onTimeoutErrorButtonClick);
          node.addWidgetEventListener('onTrackStatus', onTrackStatus);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetRef.current, isWidgetCaptureStarted, openModal]);

  const [identitytMutation] = useMutation(identity, {
    onSettled: async (data, error) => {
      const response = data ? data : error.response.data;
      await mutationHandler(response.result, response?.message);
    },
  });

  const mutationHandler = async (result, message) => {
    onStopCapture();
    identityHandler(
      result,
      handleError,
      setErrorData,
      validationGeneralError,
      message,
      handleCloseFacephiModals,
      setShowSpinner,
    );
  };

  const onStartCapture = async () => {
    const checkCapabilities = await FPhi.Selphi.Component.checkCapabilities();
    const isCapableDevice = checkCapabilities.video_devices && checkCapabilities.wasm;
    if (!isCapableDevice) {
      const response = { message: 'Este dispositivo no puede ejecutar FacePhi. Intente de nuevo con otro.' };
      onStopCapture();
      handleError(response.message, response, setErrorData, history);
    } else {
      setIsWidgetCaptureStarted(true);
    }
  };

  const onStopCapture = () => {
    turnOffCamera();
    setIsWidgetCaptureStarted(false);
    setIsWidgetCaptureStarted((state) => {
      return state;
    });
  };

  const onModuleLoaded = (eventData) => moduleLoaded('selphi', eventData);

  const onStabilizing = (stabilizingResult) => stabilizing(stabilizingResult);

  const onEndExtraction = async (bestImage, templateRaw, bestImageTokenized) => {
    let mutationValues;
    mutationValues = generateIdentityValues(
      bestImage,
      templateRaw,
      idFiles,
      documentData,
      bestImageTokenized,
    );
    identitytMutation(mutationValues);
  };

  const onExtractionFinish = async (extractionResult) => {
    setShowSpinner(true);
    let extractions = [{ detalle: 'ExtractionResult event', content: extractionResult }];
    for (let index = 0; index < extractionResult.detail.images.length; index++) {
      const image = extractionResult.detail.images[index];
      extractions = [...extractions, { detalle: `Image with index ${index}`, content: image.src }];
    }
    const bestImage = generateSelphiBestImage(extractionResult);
    const bestImageTokenized = extractionResult.detail.bestImageTokenized;
    FPhi.Selphi.Component.generateTemplateRawFromByteArray(
      resourcesPath,
      extractionResult.detail.bestImage,
      async (templateRaw) => {
        console.log('Selphi: onExtractionFinish()', [
          ...extractions,
          { detalle: 'BestImageTokenized', content: bestImageTokenized },
          { detalle: 'BestImageCropped', content: extractionResult?.detail?.bestImageCropped?.src },
          { detalle: 'BestImage', content: extractionResult?.detail?.bestImage?.src },
          { detalle: 'BestImageTemplateRaw', content: templateRaw },
        ]);
        await onEndExtraction(bestImage, templateRaw, bestImageTokenized);
      },
    );
  };

  const onUserCancel = () => {
    console.log('Selphi: onUserCancel() => isWidgetCaptureStarted', isWidgetCaptureStarted);
    localStorage.setItem('selfieCancellations', `${selfieCancellations + 1}`);
    setOpenModal(false);
    onStopCapture();
  };

  const onExceptionCaptured = (exceptionResult) => {
    console.log('Selphi: onExceptionCaptured() => exceptionResult', exceptionResult);
    localStorage.setItem('selfieExceptions', `${selfieExceptions + 1}`);
    const response = { message: 'Ocurrió una excepción. Volvé a intentar' };
    onStopCapture();
    const exceptions = +localStorage.getItem('selfieExceptions');
    if (exceptions < 3) handleError('reload', response, setErrorData, history);
    else handleError('restartFromSelphi', response, setErrorData, history);
  };

  const onExtractionTimeout = (extractionTimeoutResult) => {
    console.log('Selphi: onExtractionTimeout() => extractionTimeoutResult', extractionTimeoutResult);
    localStorage.setItem('selfieTimeouts', `${selfieTimeouts + 1}`);
    const response = { message: 'Excediste el límite de tiempo. Volvé a intentar' };
    onStopCapture();
    const timeouts = +localStorage.getItem('selfieTimeouts');
    if (timeouts < 3) handleError('reload', response, setErrorData, history);
    else handleError('restartFromSelphi', response, setErrorData, history);
  };

  const onTimeoutErrorButtonClick = () => {
    timeoutErrorButtonClick(onStopCapture);
    setErrorData({ message: 'Reinte nuevamente' });
  };

  const onTrackStatus = (eventData) => trackStatus('selphi', eventData, showLog);

  return (
    openModal && (
      <div className={classes.ModalDiv} style={{ minHeight: '665px !important' }}>
        {showSpinner ? (
          <img src={SelphiLoading} alt="loading" height="530" className={classes.FinalLoader} />
        ) : (
          <>
            <div>
              <h1>Captura de selfie</h1>
              <p>Ubicá tu cara dentro de los márgenes y esperá; haremos la captura por vos.</p>
            </div>
            <facephi-selphi
              language="es"
              style={{ height: '80%', width: '90%', margin: '0 auto' }}
              ref={widgetRef}
              bundlePath={resourcesPath}
              resourcesPath={resourcesPath + '/FPhi.Widget.Resources/'}
              graphPath={resourcesPath + '/graph.xml'}
              accessibility={false}
              accessibleElements={['button', 'buttonImage']}
              imageFormat="image/png"
              imageQuality={1.0}
              qualityThreshold={80.0}
              widgetCameraResolution="res720p"
              cameraWidth={cameraResolutions.res720p.width}
              cameraHeight={cameraResolutions.res720p.height}
              cameraType={FPhi.Selphi.CameraType.Front}
              cameraRotation={0}
              cropImage={true}
              cropFactor={1.7}
              livenessMode={FPhi.Selphi.LivenessMode.Passive}
              stabilizationStage={false}
              timeout={30000}
              registerTime={7.0}
              authenticateTime={5.0}
              tutorial={false}
              interactible={false}
              logImages={true} // En true devuelve la selphi 5 veces
              showLog={showLog}
              debugMode={false}
              faceTracking={false}
              videoRecord={false}
              videoRecordRate={10.0}
              videoRecordScale={cameraResolutions.res720p.width < 1280 ? 1 : 0.5}
              videoRecordType={FPhi.Selphi.RecorderType.Remote}
            />
          </>
        )}
      </div>
    )
  );
};

export default FacePhiSelphi;
