import React, { useState } from 'react';
import InputMask from 'react-input-mask';

const DocumentNumberField = ({ onChange, ...props }) => {
  const getRawInput = (input) => input.replace(/[^0-9A-Za-z]/g, '').toUpperCase();
  const getInitialMask = (value) => '9.999.999-*';
  const [mask, setMask] = useState(getInitialMask(''));

  const onValueChange = (event) => {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: getRawInput(event.target.value).slice(0, 8),
      },
    });
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    const value = getRawInput(oldState.value);
    if (value.length === 8 && value.match(/^\d+$/) && userInput) {
      setMask('9.999.999-*');
      return {
        selection: { start: 12, end: 12 },
        value: value + userInput,
      };
    }
    return newState;
  };

  return (
    <>
      <InputMask
        {...props}
        className="input input--margin"
        placeholder="1.234.567-8"
        mask={mask}
        onChange={onValueChange}
        beforeMaskedValueChange={beforeMaskedValueChange}
      />
    </>
  );
};

export default DocumentNumberField;
