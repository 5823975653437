import clsx from 'clsx';
import { useState } from 'react';
import { useMutation } from 'react-query';
import CheckCircle from '../../assets/ico/mobile/check-circle-solid.svg';
import UserApp from '../../assets/ico/mobile/userApp-img.svg';
import { login } from '../../services/login.service';
import RedButton from '../UI/Buttons/RedButton/RedButton';
import CustomSwitch from '../UI/Inputs/CustomSwitch/CustomSwitch';
import NormalInput from '../UI/Inputs/NormalInput/NormalInput';
import classes from './AppLogin.module.css';

function AppLogin({ setShowBiometricValidation, document, setShowLogin }) {
  const [successfulLogin, setSuccessfulLogin] = useState(false);
  const [authenticateUser, setAuthenticateUser] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [password, setPassword] = useState('');

  const manageRetriesLeft = () => {
    const retriesLeft = localStorage.getItem('loginRetriesLeft');
    localStorage.setItem('loginRetriesLeft', retriesLeft - 1);
    if (!retriesLeft) {
      setShowLogin(false);
      setShowBiometricValidation(true);
    }
  };
  // TODO: Add error when user reach 3 fail attempts

  const handleSetPassword = (e) => setPassword(e.target.value);

  const [loginMutation] = useMutation(login, {
    onSettled: (data) => {
      setSuccessfulLogin(data.successfulLogin);
      setIsAuthenticating(false);
      if (!data.successfulLogin) manageRetriesLeft();
    },
  });

  // TODO: Add error when user reach 3 fail attempts
  const handleSubmit = () => {
    setIsAuthenticating(true);
    loginMutation({ document, password });
  };

  return (
    <>
      <div className={classes.GreenBox}>
        {successfulLogin ? (
          <>
            <img src={CheckCircle} className={classes.CheckCircle} alt="Validación exitosa" />
            <p className={classes.GreenText}>¡Validación exitosa!</p>
          </>
        ) : (
          <p className={clsx(classes.GreenText, classes.FirstSubtitleTextMargin)}>
            ¡Elegiste la forma de retiro más rápida!'
          </p>
        )}
      </div>
      {!successfulLogin && (
        <div className={classes.InformationDiv}>
          <div className={classes.InputWrapper}>
            <div className={classes.InputDiv}>
              <h2 className={classes.InputTitle}>
                ¡Detectamos que tenés usuario en nuestra App! Autenticate para poder validar tu identidad
              </h2>
              <NormalInput
                wrapperClass={classes.InputMargin}
                label="Contraseña"
                type="password"
                name="password"
                value={password}
                placeholder="Contraseña"
                onChange={handleSetPassword}
                required
              />
              <span className={classes.DisclaimerText}>
                No es obligatoria la autenticación en este paso, podremos confirmar tu identidad más adelante.
              </span>
            </div>
            <img src={UserApp} alt="Retirar usando app" className={classes.UserAppImg} />
          </div>
          <div className={classes.ButtonsDiv}>
            <CustomSwitch
              label="No quiero autenticarme ahora"
              value={authenticateUser}
              setValue={setAuthenticateUser}
              name="authenticate"
            />
            <RedButton
              customClasss={classes.ContinueButton}
              isLoading={isAuthenticating}
              disabled={isAuthenticating}
              text="Confirmar"
              handleOnClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AppLogin;
