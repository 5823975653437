import Lottie from 'react-lottie';
import classes from './Lottie.module.css';

function LottieConfig({ animationData, customHeight, customWidth, loop }) {
  const defaultOptions = {
    loop: loop || false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={classes.Lottie}>
      <Lottie options={defaultOptions} height={customHeight} width={customWidth} />
    </div>
  );
}

export default LottieConfig;
