import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Layout from '../../containers/layout/Layout';
import About from '../about/About';
import Updating from '../../containers/update/Updating';
import AdditionalInformation from '../additionalInformation/AdditionalInformation';
import Credit from '../credit/Credit';
import Status from '../../containers/status/Status';
import Final from '../final/Final';
import NoOffer from '../noOffer/NoOffer';
import { fetchSimulatorParameters, checkServer } from '../../services/parameter.service';
import './App.css';

const tagManagerArgs = { gtmId: process.env.REACT_APP_TRACKING_ID };

TagManager.initialize(tagManagerArgs);

export default function App() {
  const history = useHistory();
  const [simulatorParameters, setSimulatorParameters] = useState({});

  useEffect(() => {
    fetchSimulatorParameters(1).then((res) => {
      setSimulatorParameters(res);
    });

    checkServer(1).then((res) => {
      if (!res) history.push('/updating');
    });
  }, [history]);

  return (
    <Switch>
      <Route path="/losentimos" component={NoOffer} />
      <Route path="/final" component={Final} />
      <Route path="/status" component={Status} />
      <Route path="/credit" component={Credit} />
      <Route path="/additionalInformation" component={AdditionalInformation} />
      <Route path="/about" component={About} />
      <Route path="/updating" component={Updating} />
      <Route path="/" component={() => <Layout simulatorParametersProp={simulatorParameters} />} />
    </Switch>
  );
}
