/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Link, useHistory, useLocation } from 'react-router-dom';
import FinalDesktop from '../../assets/ico/desktop/icon-final-active-desktop.svg';
import RetiroCheckDesktop from '../../assets/ico/desktop/icon-retiro-check-desktop.svg';
import SimuladorCheckDesktop from '../../assets/ico/desktop/icon-simulador-check-desktop.svg';
import SobreVosCheckDesktop from '../../assets/ico/desktop/icon-sobrevos-check-desktop.svg';
import Coins from '../../assets/ico/mobile/coins.svg';
import Final from '../../assets/ico/mobile/final-active.svg';
import Retiro from '../../assets/ico/mobile/retiro2.svg';
import SimuladorCheckMobile from '../../assets/ico/mobile/simulador-check.svg';
import SobreVosCheckMobile from '../../assets/ico/mobile/sobre-vos-check.svg';
import Footer from '../../components/UI/Footer/Footer';
import Navbar from '../../components/UI/Navbar/Navbar';

const tagManagerArgs = { dataLayer: { page: 'Lo sentimos' }, dataLayerName: 'LoSentimosDataLayer' };

const NoOffer = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({ ...location.state });
  const creditInProgress = data.response.data?.requestInProcess || data.response.requestInProcess;

  TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    setData(location.state);
    if (!location.state) history.push('/');
  }, [location, history]);

  const navigateBack = () =>
    history.push({
      pathname: data?.response?.data?.isUser ? '/about' : '/additionalInformation',
      state: data?.response?.data?.isUser ? data.data : data,
    });

  return (
    !!location.state && (
      <>
        <Navbar />
        <div className="cuerpo cuerpo--slide16">
          <div className="lat-top" />
          <div className="i-linea">
            <div className="circulo" />
            <div className="linea linea--roja linea-1" />
          </div>
          <div className="titulo">
            <h2 className="title">Solicitá tu crédito ahora</h2>
          </div>
          <div className="i-sim">
            <div className="icono">
              <img src={SimuladorCheckMobile} alt="Simulador" className="mobile" />
              <img src={SimuladorCheckDesktop} alt="Simulador" className="desktop" />
            </div>
            <div className="linea linea--roja linea-2 linea-2--slide2" />
          </div>
          <div className="lateral-sim">
            <p className="txt-lateral txt--rojo">Simulador</p>
          </div>
          <div className="card card--check simulador">
            <p className="card-title">Simulador</p>
            <span className="--rotar check" />
          </div>
          <div className="i-sob">
            <div className="icono">
              <img src={SobreVosCheckMobile} alt="Sobre vos" className="mobile" />
              <img src={SobreVosCheckDesktop} alt="Sobre vos" className="desktop" />
            </div>
            <div className="linea linea--roja linea-3 linea-3--slide3" />
          </div>
          <div className="lateral-sob">
            <p className="txt-lateral txt--rojo">Sobre vos</p>
          </div>
          <div className="card card--check sobre-vos">
            <p className="card-title">Sobre vos</p>
            <span className="--rotar check" />
          </div>
          <div className="i-forma">
            <div className="icono">
              <img src={Retiro} alt="Retiro" className="mobile" />
              <img src={RetiroCheckDesktop} alt="Retiro" className="desktop" />
            </div>
            <div className="linea linea--roja linea-4 linea-4--slide14" />
          </div>
          <div className="lateral-forma">
            <p className="txt-lateral txt--rojo">Forma de retiro</p>
          </div>
          <div className="card card--sombra card--disabled forma-retiro">
            <p className="card-title">Forma de retiro</p>
          </div>
          <div className="i-est">
            <div className="icono">
              <img src={Final} alt="Final" className="mobile" />
              <img src={FinalDesktop} alt="Final" className="desktop" />
            </div>
          </div>
          <div className="lateral-est">
            <p className="txt-lateral txt--blanco">Estado final</p>
          </div>
          <div className="card card--sombra card--active card--slide16 estado-final">
            <div className="card__header">
              <div>
                <p className="card-title">Estado final</p>
                <span className="--rotar arrow" />
              </div>
              <hr className="linea-separador" />
            </div>
            <div className="card__cuerpo card__cuerpo--slide16">
              <div>
                <p className="txt-titulo-semi-hard">
                  {creditInProgress ? '¡Ya tenés una solicitud en curso!' : '¡Lo lamentamos!'}
                </p>
              </div>
              <div className="img-degradado img-degradado--slide16">
                <p className="txt-medium">
                  {creditInProgress ? (
                    <>
                      Recibimos tu solicitud, <br />a la brevedad te contactaremos.
                    </>
                  ) : (
                    <>
                      Por ahora no tenemos una <br />
                      oferta disponible para vos.
                    </>
                  )}
                </p>
                <Link
                  to={{
                    pathname: '/about',
                    state: {
                      ...data,
                      birthdate: '',
                      cedula: '',
                      celular: '',
                      city: '',
                      cityId: '',
                      currency: '',
                      departament: '',
                      inClearing: '',
                      lastName: '',
                      name: '',
                    },
                  }}
                >
                  Reintentar con otra cédula
                </Link>
                <img src={Coins} alt="Coins" className="coins" />
              </div>
            </div>
            <div className="card__separador">
              <hr className="linea-separador" />
            </div>
            <div className="card__botones">
              <button onClick={navigateBack} className="boton boton--outline-primary boton--vovler">
                Volver
              </button>
              <button onClick={() => history.push('/')} className="boton boton--active boton--primary">
                Finalizar
              </button>
            </div>
          </div>
          <div className="lat-bot" />
          <div className="i-foot" />
          <div className="foot" />
        </div>
        <Footer />
      </>
    )
  );
};

export default NoOffer;
