import OutlinedButton from '../../Buttons/OutlinedButton/OutlinedButton';
import RedButton from '../../Buttons/RedButton/RedButton';
import classes from './Pill.module.css';

function Pill({ activePill, onSelect, text, customClass }) {
  if (activePill) {
    return (
      <RedButton
        customClass={customClass || classes.Pill}
        isLoading={false}
        text={text}
        handleOnClick={onSelect}
        type="button"
      />
    );
  } else {
    return (
      <OutlinedButton
        customClass={customClass || classes.Pill}
        isLoading={false}
        text={text}
        type="button"
        handleOnClick={onSelect}
      />
    );
  }
}

export default Pill;
