export const facePhiExceptions = [
  'documentExceptions',
  'documentCancellations',
  'documentCaptureErrors',
  'documentGeneralErrors',
  'selfieTimeouts',
  'selfieExceptions',
  'selfieCancellations',
  'selfieCaptureErrors',
  'selfieGeneralErrors',
];

export const documentTypes = [
  { id: 1, name: 'Cédula sin chip' },
  { id: 2, name: 'Cédula con chip' },
];
