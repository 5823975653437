import axios from '../axios';

export const getFooter = async (entityId) => {
  const { data } = await axios.get(`/parameter/footer/${entityId}`);
  return data.data;
};

export const fetchSimulatorParameters = async (entityId) => {
  const { data } = await axios.get(`/parameter/simulator-parameters/${entityId}`);
  return data.data;
};

export const checkServer = async (entityId) => {
  const { data } = await axios.get(`/parameter/site-online/${entityId}`);
  return data.data;
};
