import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { getFooter } from '../../../services/parameter.service';
import classes from './Footer.module.css';

function Footer() {
  const [footerText, setFooterText] = useState('');
  const location = useLocation();
  const isCreditPage = location.pathname.includes('/credit');

  useEffect(() => {
    const fetchFooter = async () => {
      const text = await getFooter(1);
      setFooterText(text);
    };
    fetchFooter();
  }, []);

  return (
    <div className={clsx(classes.Footer, isCreditPage ? classes.BotomPageFooter : classes.FixedFooter)}>
      <div className={classes.FooterText}>
        <p>www.creditodelacasa.com.uy</p>
        <p>{footerText}</p>
      </div>
    </div>
  );
}

export default Footer;
