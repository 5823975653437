import clsx from 'clsx';
import SpinnerLoader from '../../../../assets/lotties/spinner-loader-white.json';
import LottieConfig from '../../LottieConfig/LottieConfig';
import classes from './OutlinedButton.module.css';

function OutlinedButton({ type = 'submit', isLoading, disabled = false, customClass, text, handleOnClick }) {
  return (
    <button
      type={type}
      className={clsx(classes.Button, customClass)}
      disabled={isLoading || disabled}
      onClick={handleOnClick}
    >
      <div className={classes.AlignLottieBtn}>
        {text}
        {isLoading && <LottieConfig animationData={SpinnerLoader} customHeight={20} customWidth={20} />}
      </div>
    </button>
  );
}

export default OutlinedButton;
