import clsx from 'clsx';
import classes from './NormalInput.module.css';

function NormalInput({
  label,
  type,
  name,
  required,
  value,
  placeholder,
  onChange,
  wrapperClass,
  maxLength,
  disabled = false,
}) {
  return (
    <div className={wrapperClass}>
      <label htmlFor={name} className={classes.TextMedium}>
        {label} {required && '*'}
      </label>
      <input
        id={name}
        type={type || 'text'}
        name={name}
        value={value}
        className={clsx(classes.Input, classes.InputMargin)}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        onInvalid={(e) => e.target.setCustomValidity('Campo requerido.')}
        onInput={(e) => e.target.setCustomValidity('')}
        maxLength={maxLength}
        disabled={disabled}
      />
    </div>
  );
}

export default NormalInput;
