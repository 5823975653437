/* eslint-disable eqeqeq */
import { useCallback, useEffect, useRef, useState } from 'react';
import { paymentsHelper } from '../../utils/payments.helper';
import classes from './SelectAmountModal.module.css';

const ClickOutside = (handler) => {
  const domNode = useRef();
  useEffect(() => {
    const outsideHandler = (event) => {
      if (domNode.current === event.target) {
        handler();
      }
    };
    document.addEventListener('click', outsideHandler);

    return () => {
      document.removeEventListener('click', outsideHandler);
    };
  });
  return domNode;
};

const Modal = ({
  type,
  setVisible,
  amount,
  setAmount,
  payments,
  setPayments,
  fetchPlanTimer,
  simulatorParameters,
}) => {
  const [money, setMoney] = useState(amount);
  const [paym, setPaym] = useState(payments);
  const [error, setError] = useState(false);

  const replaceComma = (string) => string.replace('.', '');

  const handleChange = useCallback(
    (e) => {
      const formatValue = (value) => (value.charAt(0) === 0 ? value.substring(1) : value);

      const value = replaceComma(formatValue(e.target.value));

      e.target.value = value;
      type === 'amount' ? setMoney(value) : setPaym(value);
    },
    [type, setMoney, setPaym],
  );

  const saveModal = (money, payments) => {
    if (money > simulatorParameters.maxMonto || money < simulatorParameters.minMonto) {
      setError(true);
    } else {
      setError(false);
      setAmount(money);
      setPayments(paymentsHelper.getPaymentsValue(payments));
      fetchPlanTimer(money, payments);
      setVisible(false);
    }
  };

  const saveOnEnter = (e, money, payments) => {
    if (e.key === '.') {
      e.preventDefault();
    }
    if (e.key === 'Enter') {
      saveModal(money, payments);
    }
  };

  const saveOnEnterPayments = (e, paym) => {
    if (e.key === '.') {
      e.preventDefault();
    }
    if (e.key === 'Enter') {
      if (paym > simulatorParameters.maxCuotas || paym < simulatorParameters.minCuotas) {
        setError(true);
        setVisible(true);
      } else {
        saveModal(amount, paym);
      }
    }
  };

  const saveModalPayments = (paym) => {
    if (paym > simulatorParameters.maxCuotas || paym < simulatorParameters.minCuotas) {
      setError(true);
    } else {
      saveModal(amount, paym);
    }
  };

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      setVisible(false);
    }
  };

  const domNode = ClickOutside(() => {
    setVisible(false);
  });

  return (
    <div className={classes.Overlay} ref={domNode}>
      <div className={classes.Card}>
        <div className={classes.ButtonRight}>
          <button className={classes.CloseButton} onClick={() => setVisible(false)}>
            X
          </button>
        </div>
        <div>
          <p className={classes.ModalText}>{type === 'amount' ? 'Quiero $' : 'Cuotas'}</p>
          <input
            className={classes.ModalInput}
            type="number"
            value={type === 'amount' ? money : paym}
            onChange={handleChange}
            inputMode="numeric"
            onKeyDown={(e) =>
              type === 'amount' ? saveOnEnter(e, money, payments) : saveOnEnterPayments(e, paym)
            }
          />
          <p className={error ? classes.ModalError : classes.ModalErrorHide}>
            {type === 'amount'
              ? `El monto debe estar entre $${simulatorParameters.minMonto} y ${simulatorParameters.maxMonto}`
              : `Las cuotas deben estar entre ${simulatorParameters.minCuotas} y ${simulatorParameters.maxCuotas}`}
          </p>
          <div className="modal-separador">
            <hr className="modal-linea-separador" />
          </div>
          <div className="card__botones-modal">
            <button
              className="boton boton--primary"
              onClick={() => (type === 'amount' ? saveModal(money, payments) : saveModalPayments(paym))}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
