import { useEffect, useState } from 'react';
import CircleCheck from '../../assets/ico/mobile/check-circle-solid.svg';
import ValidationIcon from '../../assets/ico/mobile/ilu-validacion.svg';
import Accounts from '../Accounts/Accounts';
import SelphIdModal from '../SelphIdModal/SelphIdModal';
import SelphiModal from '../SelphiModal/SelphiModal';
import classes from './Validation.module.css';

const Validation = ({
  hasAccounts,
  data,
  setData,
  isNewClient,
  nextPage,
  navigateBack,
  emailErrorVisible,
  setUserHasRemainingAttempts,
  isValidated,
  setIsValidated,
  showSelphIdModal,
  setShowSelphIdModal,
  showSelphiModal,
  setShowSelphiModal,
}) => {
  const [idFiles, setIdFiles] = useState([]);
  const [documentData, setDocumentData] = useState(undefined);

  const attemps = +localStorage.getItem('validationGeneralError');
  const hasAttemptsLeft = attemps <= 3;

  const showModal = (selphId, selphi, isValidated) => {
    setShowSelphIdModal(selphId);
    setShowSelphiModal(selphi);
    setIsValidated(isValidated);
  };

  useEffect(() => {
    if (showSelphIdModal || showSelphiModal) {
      document.body.classList.add('remove-scroll');
      document.body.classList.remove('add-scroll');
    } else {
      document.body.classList.add('add-scroll');
      document.body.classList.remove('remove-scroll');
    }
  }, [showSelphIdModal, showSelphiModal]);

  useEffect(() => {
    if (!isValidated && !hasAttemptsLeft) setUserHasRemainingAttempts(false);
  }, [hasAttemptsLeft, isValidated, setUserHasRemainingAttempts]);

  return (
    <>
      {showSelphIdModal && (
        <SelphIdModal
          visible={showSelphIdModal}
          setVisible={setShowSelphIdModal}
          showModal={showModal}
          setIdFiles={setIdFiles}
          documentData={documentData}
          setDocumentData={setDocumentData}
        />
      )}
      {showSelphiModal && (
        <SelphiModal
          visible={showSelphiModal}
          setVisible={setShowSelphiModal}
          showModal={showModal}
          idFiles={idFiles}
          documentData={documentData}
          setIsValidated={setIsValidated}
          hasAttemptsLeft={hasAttemptsLeft}
        />
      )}
      {!isValidated && hasAttemptsLeft ? (
        <>
          <div className={classes.Alert}>
            <p>
              ¡Elegiste una de las formas de retiro <br />
              más rápidas!
            </p>
          </div>
          <div className={classes.Validation}>
            <img src={ValidationIcon} alt="Validación" />
            <p>Necesitamos validar tu identidad para continuar con esta forma de pago</p>
          </div>
          <div className={classes.CardSeparator}>
            <hr className={classes.LineSeparator} />
          </div>
        </>
      ) : (
        <>
          {/* Completa la accion y muestra las cuentas bancarias */}
          <div class="alert alert--succes">
            <img src={CircleCheck} alt="check" />
            <p>¡Validación exitosa!</p>
          </div>
          <Accounts
            hasAccounts={hasAccounts}
            data={data}
            setData={setData}
            isNewClient={isNewClient}
            nextPage={nextPage}
            navigateBack={navigateBack}
            emailErrorVisible={emailErrorVisible}
          />
        </>
      )}
    </>
  );
};

export default Validation;
