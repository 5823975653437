import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import App from './containers/App/App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const reactQueryConfig = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryConfigProvider client={reactQueryConfig}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </ReactQueryConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
