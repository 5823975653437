/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { useOnClickOutside } from 'usehooks-ts';
import SimuladorCheckDesktop from '../../assets/ico/desktop/icon-simulador-check-desktop.svg';
import SobreVosDesktop from '../../assets/ico/desktop/icon-sobrevos-active-desktop.svg';
import Final from '../../assets/ico/mobile/final2.svg';
import Retiro from '../../assets/ico/mobile/retiro2.svg';
import SimuladorCheckMobile from '../../assets/ico/mobile/simulador-check.svg';
import SobreVosMobile from '../../assets/ico/mobile/sobre-vos-active.svg';
import SpinnerLoader from '../../assets/lotties/spinner-loader-white.json';
import Footer from '../../components/UI/Footer/Footer';
import SelectDropdown from '../../components/UI/Inputs/SelectDropdown/SelectDropdown';
import LottieConfig from '../../components/UI/LottieConfig/LottieConfig';
import Navbar from '../../components/UI/Navbar/Navbar';
import { newUser } from '../../services/about.service';
import { getCities, getStates } from '../../services/location.service';
import classes from './AdditionalInformation.module.css';

const AdditionalInformation = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({ ...location.state });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [, setSelectedCity] = useState('');
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const tooltip = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const desiredFormat = 'yyyy-MM-DD';
  const maxDate = moment().subtract(18, 'years').format(desiredFormat);
  const minDate = moment().subtract(120, 'years').format(desiredFormat);

  const handleGetStates = async () => {
    const data = await getStates();
    setStates(data);
  };

  const handleClickOutside = () => setShow(false);

  const handleChangeState = (option) => handleGetCities(option?.name, option?.id, null);

  useOnClickOutside(tooltip, handleClickOutside);

  useEffect(() => {
    if (!location.state) history.push('/');
    setData({
      ...data,
      departament: data?.departament ? data?.departament : '',
      city: data?.city ? data?.city : '',
      ppe: true,
    });

    handleGetStates();
    if (data?.departament && data?.departamentId) {
      handleGetCities(data.departament, 'edit-states', data.departamentId);
    }
  }, []);

  const handleGetCities = async (name, id, departamentId) => {
    setSelectedCity('');
    let stateId;
    let externalId;

    if (id) {
      stateId = id || data?.stateId;
      externalId = states.find((state) => state.id === +stateId)?.externalId || data?.departamentId;
    }

    const response = await getCities(stateId);
    setCities(response);
    setData({ ...data, departament: name, departamentId: externalId, stateId: stateId });
  };

  const handleChange = useCallback((e) => {
    setData(
      {
        ...data,
        [e.target.name]: e.target.value,
      },
      [setData],
    );
  });

  const handleChangeCity = (option) => {
    setSelectedCity(option.value);
    const cityId = option.id;
    const externalId = cities.find((state) => state.id === +cityId)?.externalId;
    setData({ ...data, city: option.value, cityId: externalId }, [setData]);
  };

  const handlePPE = (e) => {
    setShow(false);
    const { checked } = e.target;
    setData({ ...data, ppe: checked });
  };

  const handleClearing = (e) => {
    const clearingValue = e.target.value;
    setData({ ...data, inClearing: clearingValue });
  };

  const handleGoBack = () => {
    history.push({
      pathname: '/about',
      state: data,
    });
  };

  const handleUserAge = (userBirthDate) => moment().diff(userBirthDate, 'years', true);

  const fetchNewUser = async (body) => {
    setIsLoading(true);
    const response = await newUser(body);
    history.push({
      pathname:
        response.data.data.data.offerAmount === 0 || response.data.data.data.requestInProcess
          ? '/losentimos'
          : '/credit',
      state: { ...data, response: response.data.data.data },
    });

    setIsLoading(false);
    return response;
  };

  const sendForm = (e) => {
    e.preventDefault();
    const body = {
      leadUuid: data.uuid,
      birthdate: moment(data.birthdate).format('DD-MM-YYYY'),
      isPEP: !data.ppe,
      departamentId: data.departamentId,
      cityId: data.cityId,
      inClearing: data.inClearing,
      firstName: data.name,
      lastName: data.lastName,
      identificationDocument: data.cedula,
      phoneNumber: data.celular,
    };

    if (
      data.inClearing === 'yes' ||
      handleUserAge(moment(data.birthdate).format('DD-MM-YYYY')) <= data.simulatorParameters.edadMinima
    ) {
      history.push({
        pathname: '/losentimos',
        state: data,
      });
    } else {
      fetchNewUser(body);
    }
  };

  return (
    !!location.state && (
      <>
        <Navbar />
        <div className={classes.MainDiv}>
          <div className={classes.LatTop} />
          <div className={classes.ILine}>
            <div className={classes.Circle} />
            <div className={clsx(classes.Line, classes.RedLine, classes.LineOne)} />
          </div>
          <div className={classes.TitleDiv}>
            <h2 className={classes.Title}>Completá tus datos y pedilo</h2>
          </div>
          <div className={classes.LateralSim}>
            <p className={clsx(classes.SideText, classes.RedText)}>Simulador</p>
          </div>
          <div className={classes.ISim}>
            <div className={classes.Icon}>
              <img src={SimuladorCheckMobile} alt="Simulador" className={classes.Mobile} />
              <img src={SimuladorCheckDesktop} alt="Simulador" className={classes.Desktop} />
            </div>
            <div className={clsx(classes.Line, classes.RedLine, classes.LineTwo)} />
          </div>
          <div>
            <div className={clsx(classes.Card, classes.CardCheck)}>
              <p className={classes.CardTitle}>Simulador</p>
              <span className={clsx(classes.Rotar, classes.Check)} />
            </div>
          </div>
          <div className={classes.LateralSob}>
            <p className={clsx(classes.SideText, classes.TextRed, classes.TextLateralActive)}>Sobre vos</p>
          </div>
          <div className={classes.ISob}>
            <div className={classes.Icon}>
              <img src={SobreVosMobile} alt="Sobre vos" className={classes.Mobile} />
              <img src={SobreVosDesktop} alt="Sobre vos" className={classes.Desktop} />
            </div>
            <div className={clsx(classes.Line, classes.GreyLine, classes.LineThree)} />
          </div>
          <div className={clsx(classes.Card, classes.CardShadow, classes.CardActive, classes.CardSlideTwo)}>
            <div className={classes.CardHeader}>
              <div>
                <p className={classes.CardTitle}>Sobre vos</p>
                <span className={clsx(classes.Rotar, classes.Arrow)} />
              </div>
              <hr className={classes.LineSeparator} />
            </div>
            <form
              onSubmit={sendForm}
              onKeyPress={(e) => {
                e.key === 'Enter' && sendForm();
              }}
            >
              <div className={clsx(classes.Data, classes.DataSlideTwo)}>
                <p className={classes.TextMedium}>Nombre *</p>
                <input
                  type="text"
                  name="name"
                  value={data.name}
                  className={clsx(classes.Input, classes.InputMargin)}
                  placeholder="Juan"
                  onChange={handleChange}
                  required
                  onInvalid={(e) => e.target.setCustomValidity('Campo requerido.')}
                  onInput={(e) => e.target.setCustomValidity('')}
                />
                <p className={classes.TextMedium}>Apellido *</p>
                <input
                  type="text"
                  name="lastName"
                  value={data.lastName}
                  className={clsx(classes.Input, classes.InputMargin)}
                  placeholder="Pérez"
                  onChange={handleChange}
                  required
                  onInvalid={(e) => e.target.setCustomValidity('Campo requerido.')}
                  onInput={(e) => e.target.setCustomValidity('')}
                />
              </div>
              <div className={clsx(classes.Data, classes.DataSlideTwo, classes.SelectDiv)}>
                <SelectDropdown
                  value={data.departament}
                  onChange={handleChangeState}
                  id="edit-states"
                  name="departament"
                  options={states}
                  required
                  labelText="Departamento"
                  displayText="name"
                  optionValue="name"
                />
                <SelectDropdown
                  value={data.city}
                  onChange={handleChangeCity}
                  id="edit-city"
                  name="city"
                  options={cities}
                  required
                  labelText="Localidad"
                  displayText="name"
                  optionValue="name"
                />
              </div>
              <div className={clsx(classes.Data, classes.DataSlideTwo)}>
                <p className={classes.TextMedium}>Fecha de nacimiento *</p>
                <input
                  type="date"
                  name="birthdate"
                  value={data.birthdate}
                  className={clsx(classes.Input, classes.InputMargin)}
                  placeholder="dd/mm/aaaa"
                  onChange={handleChange}
                  required
                  max={maxDate}
                  min={minDate}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      e.target.value !== '' ? 'Fecha de nacimiento no válida' : 'Campo necesario',
                    )
                  }
                />
                <p className={classes.TextMedium}>¿Te encuentras en clearing? *</p>
                <div className={clsx(classes.BoxSelect, classes.SelectClearing)}>
                  <select
                    id="clearing"
                    name="clearing"
                    value={data.inClearing}
                    onChange={handleClearing}
                    required
                    onInvalid={(e) => e.target.setCustomValidity('Selecciona una opción')}
                    onInput={(e) => e.target.setCustomValidity('')}
                  >
                    <option value="">Selecciona Opción</option>
                    <option value="no">No</option>
                    <option value="idk">No sé</option>
                    <option value="yes">Si</option>
                  </select>
                  <i />
                </div>
              </div>
              <div className={classes.AlignCheckboxItems}>
                <div className={classes.Checkbox} id="checkbox">
                  <input id="ppe" type="checkbox" name="ppe" onClick={handlePPE} defaultChecked={data.ppe} />
                  <label className={classes.TycLabel} htmlFor="ppe">
                    <p>No Soy Persona Políticamente Expuesta</p>
                  </label>
                </div>
                <i
                  className={clsx(classes.TooltipIcon, 'far fa-question-circle')}
                  ref={target}
                  onClick={() => setShow(!show)}
                />
                <Overlay ref={tooltip} target={target.current} show={show} placement="top">
                  <Tooltip className={classes.Tooltip}>
                    <div className={classes.TooltipCross} onClick={() => setShow(!show)}>
                      <i className={clsx(classes.TooltipIcon, 'fas fa-times')} />
                    </div>
                    Son aquellas personas que desempeñan o han desempeñado en los últimos cinco años funciones
                    públicas de importancia en el país o en el extranjero, tales como: jefes de Estado o de
                    Gobierno, políticos de jerarquía, funcionarios gubernamentales, judiciales, o militares de
                    alta jerarquía.
                  </Tooltip>
                </Overlay>
                <div className={classes.EmptyDiv} />
              </div>
              <div className={classes.CardText}>
                <p>*Campos obligatorios</p>
              </div>
              <div className={classes.CardSeparator}>
                <hr className={classes.LineSeparator} />
              </div>
              <div className={classes.CardButtons}>
                <button
                  onClick={handleGoBack}
                  className={clsx(classes.Button, classes.ButtonOutlinePrimary, classes.ButtonGoBack)}
                >
                  Volver
                </button>
                <button
                  type="submit"
                  className={clsx(classes.Button, classes.ButtonPrimary)}
                  disabled={isLoading}
                >
                  <div className={classes.AlignLottieBtn}>
                    Continuar
                    {isLoading && (
                      <LottieConfig animationData={SpinnerLoader} customHeight={20} customWidth={20} />
                    )}
                  </div>
                </button>
              </div>
            </form>
          </div>
          <div className={classes.SideWithdrawal}>
            <p className={clsx(classes.SideText, classes.GreyText)}>Forma de retiro</p>
          </div>
          <div className={classes.IForm}>
            <div className={classes.Icon}>
              <img src={Retiro} alt="Retiro" />
            </div>
            <div className={clsx(classes.Line, classes.GreyLine, classes.LineThree)}></div>
          </div>
          <div>
            <div
              className={clsx(classes.Card, classes.CardShadow, classes.CardDisabled, classes.WithdrawalForm)}
            >
              <p>Forma de retiro</p>
            </div>
          </div>
          <div className={classes.LateralEst}>
            <p className={clsx(classes.SideText, classes.GreyText)}>Estado final</p>
          </div>
          <div className={classes.IEst}>
            <div className={classes.Icon}>
              <img src={Final} alt="Final" />
            </div>
          </div>
          <div>
            <div className={clsx(classes.Card, classes.CardShadow, classes.CardDisabled)}>
              <p>Estado final</p>
            </div>
          </div>
          <div className={classes.LatBot} />
          <div className={classes.IFoot} />
          <div className={classes.Foot} />
        </div>
        <Footer />
      </>
    )
  );
};

export default AdditionalInformation;
