import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import NewDocumentImage from '../../assets/ico/desktop/cedula_dorso.png';
import OldDocumentImage from '../../assets/ico/desktop/DocumentNoChip.svg';
import { documentTypes } from '../../data/facephi.data';
import FacePhiSelphID from '../FacePhiSelphID/FacePhiSelphID';
import RedButton from '../UI/Buttons/RedButton/RedButton';
import NormalInput from '../UI/Inputs/NormalInput/NormalInput';
import Pill from '../UI/Inputs/Pill/Pill';
import classes from './SelphIdModal.module.css';

const SelphIdModal = ({
  visible,
  validated,
  showModal,
  handleCloseModal,
  setIdFiles,
  documentData,
  setDocumentData,
}) => {
  const [selectedId, setSelectedId] = useState(documentTypes[0].id);
  const [openModal, setOpenModal] = useState(false);

  const onlyLetters = 'Ingresar solo letras';
  const invalidDocumentType = 'Seleccioná el tipo de cédula de identidad';
  const requiredSerialCode = 'Ingresá la letra';
  const onlyLettersRegex = /^[a-zA-ZáéíóúÁÉÍÓÚ\s]*$/;

  const IdentityDocumentDataSchema = Yup.object().shape({
    documentType: Yup.number().oneOf([1, 2], invalidDocumentType).required(invalidDocumentType),
    serialCode: Yup.string().when(['documentType'], {
      is: (documentType) => documentType === 1,
      then: Yup.string()
        .matches(onlyLettersRegex, onlyLetters)
        .max(1, onlyLetters)
        .min(1, onlyLetters)
        .required(requiredSerialCode),
      otherwise: Yup.string().nullable(),
    }),
  });

  const handleEndExtracionCorrectly = () => showModal(false, true, false);

  const submitForm = (values, actions) => {
    setTimeout(() => {
      actions.setSubmitting(false);
      setDocumentData({ documentType: values.documentType, serialCode: values?.serialCode?.toUpperCase() });
      setOpenModal(true);
    }, 1000);
  };

  const handleSelectPill = (id, setFieldValue) => {
    setSelectedId(id);
    setFieldValue('documentType', id);
  };

  return (
    visible && (
      <div className={classes.Overlay}>
        <div className={clsx(classes.MainDiv, openModal && classes.FacePhiSelphIdModal)}>
          <div className={classes.RightButton}>
            <button className={classes.CloseButton} onClick={() => showModal(false, false, false)}>
              X
            </button>
          </div>
          {!openModal ? (
            <div className={classes.ModalDiv}>
              <div>
                <div>
                  <h1>Autenticación</h1>
                  <p>Para validar tu identidad vas a necesitar:</p>
                </div>
                <Formik
                  validateOnMount
                  initialValues={{ documentType: selectedId, serialCode: '' }}
                  validationSchema={IdentityDocumentDataSchema}
                  onSubmit={submitForm}
                >
                  {({ values, setSubmitting, setFieldValue, isNewDocument = values.documentType === 2 }) => (
                    <Form className={classes.Form}>
                      <div className={classes.InstructionsDiv}>
                        <div>
                          <p>El frente y el dorso de tu cédula</p>
                          <p>Ubicarte en un lugar con luz adecuada para la captura de una selfie</p>
                        </div>
                        <div className={classes.PillsDiv}>
                          {documentTypes.map((id) => (
                            <Pill
                              text={id.name}
                              activePill={id.id === values.documentType}
                              key={id.id}
                              onSelect={() => handleSelectPill(id.id, setFieldValue)}
                              customClass={classes.ButtonPill}
                            />
                          ))}
                        </div>
                        <div>
                          <img
                            src={isNewDocument ? NewDocumentImage : OldDocumentImage}
                            alt={isNewDocument ? 'Cédula nueva' : 'Número de serie en documento viejo'}
                            className={classes.DocumentImage}
                          />
                          <NormalInput
                            wrapperClass={isNewDocument ? classes.HideInput : classes.ShowInput}
                            type="text"
                            name="serialCode"
                            label="Si tu cédula es de este tipo, escribí la última letra que figura antes del número"
                            maxLength={1}
                            uppercase
                            disabled={isNewDocument}
                          />
                        </div>
                      </div>
                      <RedButton
                        type="button"
                        text="Continuar"
                        customClass={classes.SerialCodeInput}
                        isLoading={false}
                        handleOnClick={() => {
                          setTimeout(() => {
                            setSubmitting(false);
                            setDocumentData({
                              documentType: values.documentType,
                              serialCode: values?.serialCode?.toUpperCase(),
                            });
                            setOpenModal(true);
                          }, 1000);
                        }}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <FacePhiSelphID
              openModal={openModal}
              handleEndExtracionCorrectly={handleEndExtracionCorrectly}
              documentData={documentData}
              setIdFiles={setIdFiles}
            />
          )}
        </div>
      </div>
    )
  );
};

export default SelphIdModal;
